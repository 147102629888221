import React from "react";

// Props & State
export type FooterProps = {};

type FooterState = {};

export class Footer extends React.Component<FooterProps, FooterState> {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">{new Date().getFullYear()} &copy; BoneX Fund</div>
              <div className="col-md-6">
                <div className="text-sm-end d-none d-sm-block">
                  <a href="/terms" target="_blank">
                    Terms and conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
