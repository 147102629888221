import React from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { AppContext } from "../context/AppProvider";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";

type SidebarProps = {};

type SidebarState = {
  isUserAmbassador: boolean;
};

class SidebarContent extends React.Component<SidebarProps, SidebarState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: SidebarState = {
    isUserAmbassador: false,
  };

  async componentDidMount(): Promise<void> {
    const ambassadorData = await PortCentralServer.Repo.client.getAmbassadorData();

    if (ambassadorData && ambassadorData.activeYn) {
      this.setState({
        isUserAmbassador: true
      })
    }

  }

  handleSubmitSignOut = async (e: any) => {
    e.preventDefault();
    await this.context.signOut();
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/dashboard">
                  <i className="bx bxs-dashboard"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li>
                <Link to="/accounts">
                  <i className="bx bxs-user-detail"></i>
                  <span>Accounts</span>
                </Link>
              </li>
              <li>
                <Link to="/earns">
                  <i className="bx bxs-data"></i>
                  <span>Earns</span>
                </Link>
              </li>
              <li>
                <Link to="/pricing">
                  <i className="bx bx-dollar"></i>
                  <span>Pricing</span>
                </Link>
              </li>
              <li>
                <Link to="/billing">
                  <i className="bx bx-receipt"></i>
                  <span>Billing</span>
                </Link>
              </li>
              <li>
                <Link to="/profile">
                  <i className="bx bx-user"></i>
                  <span>Profile</span>
                </Link>
              </li>
              <li>
                <Link to="/affiliate-apply">
                  <i className="bx bxs-file"></i>
                  <span>Apply As Partner</span>
                </Link>
              </li>
              {
                this.state.isUserAmbassador ?
                  <li>
                    <Link to="/ambassador">
                      <i className="bx bxs-analyse"></i>
                      <span>Ambassador</span>
                    </Link>
                  </li> : <></>
              }
              <li>
                <Link to="/affiliate">
                  <i className="bx bxs-analyse"></i>
                  <span>Affiliate</span>
                </Link>
              </li>
              <li>
                <Link to="/affiliate-payouts">
                  <i className="bx bxs-dollar-circle"></i>
                  <span>Affiliate Payouts</span>
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <i className="bx bx-message-rounded-dots"></i>
                  <span>Contact</span>
                </Link>
              </li>
              {/* <li>
                <Link onClick={this.handleSubmitSignOut} to={"/"}>
                  <i className="bx bx-power-off font-size-16 align-middle" />
                  <span>Logout</span>
                </Link>
              </li> */}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

export default SidebarContent;
