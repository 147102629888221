import React from "react";
import ReactPixel from 'react-facebook-pixel';
import { MoonPayBuyWidget } from "@moonpay/moonpay-react";
import { Transak, TransakConfig } from "@transak/transak-sdk";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { IProgramAddonItem } from "../../lib/drivers/dto/config/IProgramAddonItem";
import { ProgramAddonType } from "../../lib/drivers/dto/enums/ProgramAddonType";
import { PurchaseProviderType } from "../../lib/drivers/dto/enums/PurchaseProviderType";
import { FundTradingProgramDto } from "../../lib/drivers/dto/programs/FundTradingProgramDto";
import withRouterHook from "../../withRouterHook";
import { Input } from "reactstrap";
import { PromoCodeDto } from "../../lib/drivers/dto/promo-codes/PromoCodeDto";

const DETAILS: { [key: number]: { accountSizeNum: string; title: string; subtitle: string } } = {
  1_000: { accountSizeNum: "1000", title: "Base Tier", subtitle: "Base Tier" },
  2_500: { accountSizeNum: "2500", title: "Base Tier", subtitle: "Base Tier" },
  5_000: { accountSizeNum: "5000", title: "Kickstarter Tier", subtitle: "Start Small, Dream Big: Begin Your Trading Journey with Essential Funding." },
  10_000: { accountSizeNum: "10000", title: "Starter Plus Tier", subtitle: "Step Up Your Game: More Funding for Confident Beginnings." },
  25_000: { accountSizeNum: "25000", title: "Accelerator Tier", subtitle: "Boost Your Potential: Enhanced Funding for Ambitious Traders." },
  50_000: { accountSizeNum: "50000", title: "Advanced Tier", subtitle: "Take the Lead: Substantial Funding for Growing Traders." },
  100_000: { accountSizeNum: "100000", title: "Pro Tier", subtitle: "Maximize Your Impact: Premium Funding for Serious Traders." },
  200_000: { accountSizeNum: "200000", title: "Elite Tier", subtitle: "Unlock the Ultimate: Top-Tier Funding for Expert-Level Trading." },
  500_000: { accountSizeNum: "200000", title: "Elite Tier", subtitle: "Unlock the Ultimate: Top-Tier Funding for Expert-Level Trading." },
  1_000_000: { accountSizeNum: "200000", title: "Elite Tier", subtitle: "Unlock the Ultimate: Top-Tier Funding for Expert-Level Trading." }
};

type AddonSelectionItem = {
  addon: IProgramAddonItem;
  selected: boolean;
  title: string;
};

type PricingDetailsPageParams = ReturnType<typeof useParams> & {
  fundTradingProgramId: string;
};

export type PricingDetailsPageProps = {
  location?: ReturnType<typeof useLocation>;
  params?: PricingDetailsPageParams;
};

type PaymentOptions = {
  usdt: boolean;
  card: boolean;
};

type MoonpayData = {
  visible: boolean;
  baseCurrencyCode: string;
  baseCurrencyAmount: number;
  defaultCurrencyCode: string;
  walletAddress: string;
  showWalletAddressForm: boolean;
  lockAmount: boolean;
  email: string;
  externalTransactionId: string;
  externalCustomerId: string;
};

type PricingDetailsPageState = {
  here: boolean;
  activeTab: number;
  program: FundTradingProgramDto;
  initialPrice: number;
  initialDiscountPrice?: number | null;
  programId: string;
  userSubscriptionPurchaseId: string;
  userEmail: string;
  paymentOption: PaymentOptions;
  moonpayData: MoonpayData;
  addons: AddonSelectionItem[];
  promoCode: string;
};

class PricingDetailsPage extends React.Component<PricingDetailsPageProps, PricingDetailsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: PricingDetailsPageState = {
    here: true,
    activeTab: 1,
    program: null,
    initialPrice: 0,
    initialDiscountPrice: null,
    programId: "",
    userSubscriptionPurchaseId: null,
    userEmail: null,
    paymentOption: {
      usdt: true,
      card: false
    },
    moonpayData: {
      visible: false,
      baseCurrencyCode: "usd",
      baseCurrencyAmount: 0,
      // TODO => Change currency and wallet
      defaultCurrencyCode: "eth",
      walletAddress: process.env.REACT_APP_MOONPAY_RECIEVE_WALLET,
      showWalletAddressForm: false,
      lockAmount: true,
      email: "",
      externalTransactionId: "",
      externalCustomerId: ""
    },
    addons: null,
    promoCode: ""
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const fundTradingProgramId = this.props.params.fundTradingProgramId;
    const currProgram: FundTradingProgramDto = await PortCentralServer.Repo.client.getFundTradingProgram(fundTradingProgramId);

    const addons: IProgramAddonItem[] = await PortCentralServer.Repo.client.getFundTradingProgramAddonList();

    const addonSelectionList: AddonSelectionItem[] = addons.map((addon) => ({
      addon,
      selected: false, // default selection state
      title: this.getAddonTitle(addon.type)
    }));

    await this.setState({
      ...this.state,
      program: currProgram,
      initialPrice: currProgram.price,
      initialDiscountPrice: currProgram.discountPrice,
      programId: fundTradingProgramId,
      addons: addonSelectionList
    });
  };

  componentDidUpdate = async (prevProps: PricingDetailsPageProps, prevState: PricingDetailsPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handleAddonClick = async (event: React.MouseEvent<HTMLAnchorElement>, type: ProgramAddonType) => {
    event.preventDefault();

    // reset this.state.userSubscriptionPurchaseId

    const newAddons = this.state.addons.map((item) => {
      if (item.addon.type === type) {
        // Toggle the selected state of the clicked addon
        return { ...item, selected: !item.selected };
      }

      // If the current addon is of type BI_WEEKLY_PAYOUT or WEEKLY_PAYOUT, and a different one is selected, deselect it
      if (
        (type === ProgramAddonType.BI_WEEKLY_PAYOUT && item.addon.type === ProgramAddonType.WEEKLY_PAYOUT) ||
        (type === ProgramAddonType.WEEKLY_PAYOUT && item.addon.type === ProgramAddonType.BI_WEEKLY_PAYOUT)
      ) {
        return { ...item, selected: false };
      }

      return item;
    });

    await this.setState({ addons: newAddons });

    await this.calculatePrice();
  };

  toggleTab = (tab: number) => {
    if (this.state.activeTab !== tab) {
      // var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 3) {
        this.setState({
          ...this.state,
          activeTab: tab
        });
        // setActiveTab(tab)
        // setPassedSteps(modifiedSteps)
      }
    }
  };

  submitPaymentForm(vendor: string, amount: number, symbol: string, inv: string, email: string): void {
    // Create a blurred background overlay
    const overlay = document.createElement("div");
    overlay.style.position = "fixed";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.width = "100vw";
    overlay.style.height = "100vh";
    overlay.style.backgroundColor = "transparent"; // Semi-transparent black background
    overlay.style.backdropFilter = "blur(5px)"; // Apply blur effect to the background
    overlay.style.zIndex = "999"; // Ensure the overlay is on top
    document.body.appendChild(overlay);

    // Create the iframe
    const iframe = document.createElement("iframe");
    iframe.name = "paymentFrame";
    iframe.style.position = "fixed";
    iframe.style.top = "50%";
    iframe.style.left = "50%";
    iframe.style.transform = "translate(-50%, -50%)"; // Center the iframe

    // Set responsive width/height for mobile
    iframe.style.width = "80vw"; // 90% of viewport width
    iframe.style.height = "80vh"; // 80% of viewport height
    iframe.style.border = "1px solid black";
    iframe.style.borderRadius = "10px"; // Rounded corners for better UX
    iframe.style.overflow = "auto"; // Add scroll if content overflows
    iframe.style.zIndex = "1000"; // Ensure the iframe is above the overlay
    document.body.appendChild(iframe);

    // Create the close button
    const closeButton = document.createElement("button");
    closeButton.innerText = "X";
    closeButton.style.position = "fixed";
    closeButton.style.top = "calc(50% - 37vh)"; // Adjust relative to iframe height
    closeButton.style.left = "calc(50% + 40vw - 25px)"; // Adjust relative to iframe width
    closeButton.style.transform = "translate(-50%, -50%)";
    closeButton.style.backgroundColor = "#000";
    closeButton.style.color = "#fff";
    closeButton.style.border = "none";
    closeButton.style.padding = "10px";
    closeButton.style.fontSize = "18px";
    closeButton.style.cursor = "pointer";
    closeButton.style.zIndex = "1001"; // Ensure the button is on top
    document.body.appendChild(closeButton);

    // Handle closing the iframe and overlay
    closeButton.onclick = () => {
      document.body.removeChild(iframe);
      document.body.removeChild(overlay);
      document.body.removeChild(closeButton);
    };

    // Create the form and set its target to the iframe
    const form = this.createPaymentForm(vendor, amount, symbol, inv, email);
    form.target = "paymentFrame";
    document.body.appendChild(form);

    // Submit the form
    form.submit();
  }

  createPaymentForm(vendor: string, amount: number, symbol: string, inv: string, email: string): HTMLFormElement {
    const form = document.createElement("form") as HTMLFormElement;
    form.method = "POST";
    form.action = "https://payment.bonex.fund/order_popup";
    form.target = "paymentFrame"; // Set the target to the iframe

    form.appendChild(this.createHiddenInput("vendor", vendor));
    form.appendChild(this.createHiddenInput("amount", amount.toString()));
    form.appendChild(this.createHiddenInput("symbol", symbol));
    form.appendChild(this.createHiddenInput("inv", inv));
    form.appendChild(this.createHiddenInput("email", email));

    return form;
  }

  createHiddenInput(name: string, value: string): HTMLInputElement {
    const input = document.createElement("input") as HTMLInputElement;
    input.type = "hidden";
    input.name = name;
    input.value = value;
    return input;
  }

  calculateTotalMoonpayPrice(amount: number) {
    const processingFeePercentage = 0.045;
    const minFee = 3.99;
    const networkFee = 2;

    // Calculate the fee (either 4.5% of the amount or $3.99, whichever is higher)
    const fee = Math.max(amount * processingFeePercentage, minFee);

    // Return the total cost (amount + fee)
    return amount + fee + networkFee;
  }

  async applyPromoCode() {
    const promoCodeData: PromoCodeDto = await PortCentralServer.Repo.client.getPromoCode(this.state.promoCode);

    if (promoCodeData && promoCodeData.active) {
      const { discountPercentage } = promoCodeData;
      const { price } = this.state.program;

      const promoCodeDiscount = price * (1 - (Number(discountPercentage) / 100));

      this.setState({
        program: {
          ...this.state.program,
          discountPercentage: discountPercentage,
          discountPrice: promoCodeDiscount
        }
      })
    }
  }

  handleInputChange = (e: any) => {
    this.setState({ promoCode: e.target.value });
  };

  async fetchCurrentNetworkFee(): Promise<number> {
    const response = await fetch("https://api.etherscan.io/api?module=proxy&action=eth_gasPrice&apikey=D2BKV896XWKP8EGM7N1TGQT77TR4V2VJ38");
    const data = await response.json();
    const gasPriceGwei = parseInt(data.result, 16) / 1e9; // Convert from wei to Gwei

    // Assuming an average gas limit of 21000 for standard ETH transactions, adjust as necessary for USDT
    const gasLimit = 21000; // You can adjust this for USDT transactions
    const networkFeeInEth = (gasPriceGwei * gasLimit) / 1e9; // Convert Gwei to Ether

    // Fetch current ETH price in USD to convert network fee to USD
    const ethPriceResponse = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd");
    const ethPriceData = await ethPriceResponse.json();
    const ethPriceInUSD = ethPriceData.ethereum.usd;

    // Convert network fee from ETH to USD
    const networkFeeInUSD = networkFeeInEth * ethPriceInUSD;

    return networkFeeInUSD;
  }

  async calculateTotalTransakPrice(amount: number, countryCode: string): Promise<number> {
    const processingFeePercentage = 5;
    const constantFee = 2;

    // Fetch the current network fee
    const networkFee = await this.fetchCurrentNetworkFee();

    const fee = Math.max(amount * (processingFeePercentage / 100));

    // Return the total cost (amount + fee + current network fee)
    return amount + fee + constantFee + networkFee * 5;
  }

  processBuy = async () => {
    const addons = this.getSelectedAddons();

    ReactPixel.init('980380830624140');
    ReactPixel.track('Purchase', {
      content_name: 'Funded Account',
      content_category: 'Funded Accounts',
      content_ids: ['1234'],
      content_type: 'product',
      value: 49,
      currency: 'USD',
    });

    if (this.state.paymentOption.usdt) {
      if (!this.state.userSubscriptionPurchaseId) {
        const { userSubscriptionPurchaseId, userEmail } = await PortCentralServer.Repo.client.buySubscription({
          fundTradingProgramId: this.state.programId,
          provider: PurchaseProviderType.BXCHECKOUT,
          addons: addons,
          promoCode: this.state.promoCode
        });

        this.setState({
          ...this.state,
          userSubscriptionPurchaseId: userSubscriptionPurchaseId,
          userEmail: userEmail
        });

        this.createInvoice(userSubscriptionPurchaseId, userEmail);
      } else {
        this.createInvoice(this.state.userSubscriptionPurchaseId, this.state.userEmail);
      }
    } else if (this.state.paymentOption.card) {
      // const { userSubscriptionPurchaseId, userEmail, userId } = await PortCentralServer.Repo.client.buySubscription({
      //   fundTradingProgramId: this.state.programId,
      //   provider: PurchaseProviderType.MOONPAY,
      //   network: "ethereum",
      //   symbol: this.state.moonpayData.defaultCurrencyCode.toUpperCase(),
      //   address: this.state.moonpayData.walletAddress,
      // });

      // this.setState({
      //   ...this.state,
      //   moonpayData: {
      //     ...this.state.moonpayData,
      //     visible: true,
      //     baseCurrencyAmount: this.calculateTotalMoonpayPrice(Number(this.state.program?.discountPrice || this.state.program.price)),
      //     email: userEmail,
      //     externalTransactionId: userSubscriptionPurchaseId,
      //     externalCustomerId: userId
      //   }
      // })

      const { userSubscriptionPurchaseId, userEmail, userId } = await PortCentralServer.Repo.client.buySubscription({
        fundTradingProgramId: this.state.programId,
        provider: PurchaseProviderType.TRANSAK,
        network: "ethereum",
        symbol: this.state.moonpayData.defaultCurrencyCode.toUpperCase(),
        address: this.state.moonpayData.walletAddress,
        addons: addons,
        promoCode: this.state.promoCode
      });

      const request = await fetch("https://ipinfo.io/json?token=18000387090f02");
      const jsonResponse = await request.json();
      const countryCode = jsonResponse.country;

      const fiatAmount = await this.calculateTotalTransakPrice(Number(this.state.program?.discountPrice || this.state.program.price), countryCode);

      const transakConfig: TransakConfig = {
        apiKey: process.env.REACT_APP_TRANSAK_API_KEY,
        environment: Transak.ENVIRONMENTS.PRODUCTION,
        productsAvailed: "BUY",
        network: "ethereum",
        countryCode: countryCode,
        fiatCurrency: "USD",
        fiatAmount,
        cryptoCurrencyCode: "USDT",
        walletAddress: process.env.REACT_APP_TRANSAK_RECIEVE_WALLET,
        email: userEmail,
        partnerOrderId: userSubscriptionPurchaseId,
        partnerCustomerId: userId
      };

      const transak = new Transak(transakConfig);

      transak.init();

      Transak.on("*", (data: any) => {
        // console.log(data);
      });

      // This will trigger when the user closed the widget
      Transak.on(Transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
        transak.close();
      });

      /*
       * This will trigger when the user has confirmed the order
       * This doesn't guarantee that payment has completed in all scenarios
       * If you want to close/navigate away, use the TRANSAK_ORDER_SUCCESSFUL event
       */
      Transak.on(Transak.EVENTS.TRANSAK_ORDER_CREATED, (orderData) => {
        // console.log(orderData);
      });

      /*
       * This will trigger when the user marks payment is made
       * You can close/navigate away at this event
       */
      Transak.on(Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
        // console.log(orderData);
        transak.close();
      });
    }
  };

  createInvoice = async (userSubscriptionPurchaseId: string, userEmail: string) => {
    this.submitPaymentForm("123456789abcdef", this.state.program?.discountPrice || this.state.program.price, "USDT", userSubscriptionPurchaseId, userEmail);
  };

  handleGetSignature = async (url: string): Promise<string> => {
    return await PortCentralServer.Repo.client.getSignature(url);
  };
  // #endregion

  // #region State Setters
  setActiveTab = (tab: number) => {
    this.setState({
      ...this.state,
      activeTab: tab
    });
  };
  // #endregion

  // #region Functions
  getAddonTitle(type: ProgramAddonType): string {
    switch (type) {
      case ProgramAddonType.BI_WEEKLY_PAYOUT:
        return "Bi-Weekly Payout";
      case ProgramAddonType.WEEKLY_PAYOUT:
        return "Weekly Payout";
      case ProgramAddonType.NO_MIN_TRADING_DAYS:
        return "No Minimum Trading Days";
      default:
        return "Unknown Addon";
    }
  }

  async calculatePrice(): Promise<void> {
    const initialPrice: number = Number(this.state.initialPrice);
    const initialDiscountPrice: number = this.state.initialDiscountPrice;

    let actualPrice: number = Number(this.state.initialPrice);
    let actualDiscountPrice: number = Number(this.state.initialDiscountPrice);

    for (const selectedAddon of this.state.addons) {
      if (selectedAddon.selected === true) {
        const addonCost = initialPrice * (selectedAddon.addon.priceChangePercentage / 100);
        actualPrice = actualPrice + addonCost;

        if (this.state.initialDiscountPrice && this.state.initialDiscountPrice > 0) {
          const addonCostDiscount = initialDiscountPrice * (selectedAddon.addon.priceChangePercentage / 100);
          actualDiscountPrice = actualDiscountPrice + addonCostDiscount;
        }
      }
    }

    // round
    const roundedPrice = Math.round(actualPrice * 100) / 100;
    const roundedDiscountPrice = Math.round(actualDiscountPrice * 100) / 100;

    await this.setState((prevState) => ({
      program: {
        ...prevState.program,
        price: roundedPrice,
        discountPrice: roundedDiscountPrice
      }
    }));
  }

  getSelectedAddons(): IProgramAddonItem[] {
    const result = this.state.addons.filter((addonItem) => addonItem.selected).map((addonItem) => addonItem.addon);

    return result;
  }

  isAddonSelected(type: ProgramAddonType): boolean {
    const targetAddon = this.state.addons.find((ele) => ele.selected && ele.addon.type == type);

    return targetAddon != null;
  }
  // #endregion

  render() {
    let currentTitle = { accountSizeNum: "", title: "", subtitle: "" };
    let programTitle = "";
    let programSubtitle = "";
    if (this.state.program !== null) {
      currentTitle = DETAILS[Number(this.state.program.accountSize)];
      programTitle = currentTitle.title;
      programSubtitle = currentTitle.subtitle;
    }

    let selectedAddons = this.state.addons?.filter(addon => addon.selected);

    let buttonText = "Next";

    if (this.state.activeTab === 2) {
      buttonText = "Checkout";
    } else if (this.state.activeTab === 3) {
      buttonText = "Pay Now"
    }

    // const programTitle = currentTitle[0].title

    return (
      <React.Fragment>
        <Layout>
          {this.state.moonpayData.visible ? (
            <MoonPayBuyWidget
              visible={this.state.moonpayData.visible}
              variant="overlay"
              baseCurrencyCode={this.state.moonpayData.baseCurrencyCode}
              baseCurrencyAmount={this.state.moonpayData.baseCurrencyAmount.toString()}
              defaultCurrencyCode={this.state.moonpayData.defaultCurrencyCode}
              walletAddress={this.state.moonpayData.walletAddress}
              showWalletAddressForm={this.state.moonpayData.showWalletAddressForm.toString()}
              lockAmount={this.state.moonpayData.lockAmount.toString()}
              email={this.state.moonpayData.email}
              externalTransactionId={this.state.moonpayData.externalTransactionId}
              externalCustomerId={this.state.moonpayData.externalCustomerId}
              onUrlSignatureRequested={this.handleGetSignature}
            />
          ) : (
            <></>
          )}
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Subscription" breadcrumbItem="Details" />
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="text-center mb-5">
                    <h4>Select Your Funded Trading Program</h4>
                    <p className="text-center">
                      To do this, uniform guidelines and clear evaluation criteria are needed. Standardizing processes across programs can streamline the path for traders.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Basic Wizard</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <li className={`nav-item ${this.state.activeTab === 1 ? "current" : ""}`}>
                          <a
                            role="button"
                            className={`nav-link ${this.state.activeTab === 1 ? "current" : ""}`}
                            onClick={() => {
                              if (this.state.activeTab !== 3) {
                                this.setActiveTab(1);
                              }
                            }}
                          // disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Program Details
                          </a>
                        </li>
                        <li className={`nav-item ${this.state.activeTab === 2 ? "current" : ""}`}>
                          <a
                            role="button"
                            className={`nav-link ${this.state.activeTab === 2 ? "active" : ""}`}
                            onClick={() => {
                              if (this.state.activeTab !== 3) {
                                this.setActiveTab(2);
                              }
                            }}
                          // disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Payment Provider
                          </a>
                        </li>
                        <li className={`nav-item ${this.state.activeTab === 3 ? "current" : ""}`}>
                          <a
                            role="button"
                            className={`nav-link ${this.state.activeTab === 3 ? "active" : ""}`}
                            onClick={() => {
                              this.setActiveTab(3);
                            }}
                          // disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">3.</span> Checkout
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <div className={`tab-content body ${this.state.activeTab === 1 ? "active" : ""}`}>
                        <div className={`tab-pane ${this.state.activeTab === 1 ? "active" : ""}`}>
                          {
                            // currentTitle[0].
                            <div>
                              <h5>
                                {currentTitle && currentTitle.title} ${currentTitle && Number(currentTitle.accountSizeNum).toLocaleString()}{" "}
                              </h5>
                              <h5 className="mb-4">
                                {/* {currentTitle && currentTitle[0].title} {currentTitle && currentTitle[0].accountSizeNum} {" "} */}
                                {this.state.program?.discountPrice ? (
                                  <>
                                    <del>
                                      Price :{" "}
                                      <span className="me-2">
                                        <b>{this.state.program && Number(this.state.program.price).toLocaleString()} USDT</b>
                                      </span>{" "}
                                    </del>
                                    <br />
                                    <>
                                      Discount Price :{" "}
                                      <span className="me-2">
                                        <b style={{ color: "gold" }}>{this.state.program && this.state.program.discountPrice.toLocaleString()} USDT</b>
                                      </span>{" "}
                                    </>
                                  </>
                                ) : (
                                  <>
                                    Price :{" "}
                                    <span className="me-2">
                                      <b>{this.state.program && Number(this.state.program.price).toLocaleString()} USDT</b>
                                    </span>{" "}
                                  </>
                                )}
                              </h5>
                              <p className="mb-4">{currentTitle && currentTitle.subtitle}</p>{" "}
                            </div>
                          }
                          <div className="row">
                            {this.state.program !== null &&
                              this.state.program.fundTradingProgramStages.map((stage, index) => (
                                <div key={index} className="plan-features mt-5 col-md-4">
                                  <h3>
                                    {stage.type} {stage.type === "PHASE" ? stage.sequence + 1 : ""}
                                  </h3>
                                  <p>
                                    <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                    <span className="token-stage-bonus">{stage.maxDailyLoss}%</span> <span className="token-stage-cap">Max daily loss</span>
                                  </p>
                                  {stage.maxTotalLoss && (
                                    <p>
                                      <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                      <span className="token-stage-bonus">{stage.maxTotalLoss}%</span> <span className="token-stage-cap">Max loss</span>
                                    </p>
                                  )}
                                  {stage.maxTrailingLoss && (
                                    <p>
                                      <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                      <span className="token-stage-bonus">{stage.maxTrailingLoss}%</span> <span className="token-stage-cap">Max trailing loss</span>
                                    </p>
                                  )}
                                  {stage.profitTarget !== null && (
                                    <p>
                                      <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                      <span className="token-stage-bonus">{stage.profitTarget} %</span> <span className="token-stage-cap">Phase {[index + 1]} target</span>
                                    </p>
                                  )}

                                  {stage.minTradingDays !== null && (
                                    <>
                                      {this.isAddonSelected(ProgramAddonType.NO_MIN_TRADING_DAYS) ? (
                                        <>
                                          <p>
                                            <del>
                                              <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                              <span className="token-stage-bonus">{stage.minTradingDays}</span> <span className="token-stage-cap">Min trading days</span>
                                            </del>
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p>
                                            <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                            <span className="token-stage-bonus">{stage.minTradingDays}</span> <span className="token-stage-cap">Min trading days</span>
                                          </p>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {/* {this.state.program !== null && this.state.program.fundTradingProgramStages.length > 2 && (
                                  <p>
                                    <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                    <span className="token-stage-bonus">
                                      {this.state.program !== null ? this.state.program.fundTradingProgramStages[1].profitTarget : ""}%
                                    </span>{" "}
                                    <span className="token-stage-cap">Phase 2 target</span>
                                  </p>
                                )} */}
                                </div>
                              ))}
                          </div>
                          <hr />
                          {this.state.addons && (
                            <div className="row mt-4">
                              <div className="col">
                                <div className="product-color">
                                  <h5 className="font-size-15">Addons :</h5>
                                  {this.state.addons.map((item: AddonSelectionItem, key: number) => (
                                    <span key={key} className="me-3">
                                      <a role="button" className={`${item.selected ? "active" : ""}`} onClick={(e) => this.handleAddonClick(e, item.addon.type)}>
                                        <div className="product-color-item border rounded p-5">
                                          {/* <img src="/static/media/img-1.fc0c27967f674639fc3d.png" alt="" className="avatar-md" /> */}
                                          {item.title}
                                        </div>
                                        <p>+ {item.addon.priceChangePercentage} %</p>
                                      </a>
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="d-flex align-items-center mt-4">
                            <h5 className="font-size-15 me-3 mb-0">Promo code:</h5>
                            <Input
                              name="promoCode"
                              className="form-control me-3"
                              type="text"
                              placeholder="Enter promo code"
                              style={{ width: '20rem' }}
                              onChange={this.handleInputChange}
                            />
                            <button className="btn btn-primary" type="submit" onClick={() => this.applyPromoCode()}>
                              Apply
                            </button>
                          </div>

                        </div>
                      </div>
                      <div className={`tab-content body ${this.state.activeTab === 2 ? "active" : ""}`}>
                        <div className={`tab-pane ${this.state.activeTab === 2 ? "active" : ""}`}>
                          <form>
                            <div className="row">
                              <div className="col lg-6">
                                <h3>Payment method</h3>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="option1"
                                    onClick={() => this.setState({ ...this.state, paymentOption: { card: false, usdt: true } })}
                                    defaultChecked={this.state.paymentOption.usdt}
                                  />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    USDT
                                  </label>
                                  <br />
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios2"
                                    value="option1"
                                    onClick={() => this.setState({ ...this.state, paymentOption: { card: true, usdt: false } })}
                                    defaultChecked={this.state.paymentOption.card}
                                  />
                                  <label className="form-check-label" htmlFor="exampleRadios2">
                                    Credit Card
                                  </label>
                                </div>
                              </div>

                              <div className="col lg-6">
                                <div className="mb-3">
                                  <p>
                                    By depositing USDT or other supported cryptocurrencies through our platform, or by using third-party credit card payment providers, you agree to
                                    our terms and conditions. Deposits are final, non-refundable, and subject to blockchain network fees. Please ensure the accuracy of your deposit
                                    address, as we are not responsible for errors or losses.
                                  </p>
                                  <strong>
                                    <p>
                                      Account credentials will be set within 24 hours during business days (Monday to Friday).
                                    </p>
                                    <p>
                                      Opening orders on any protocol other than the following may result in account suspension: ETH, BTC, LTC, XRP, ADA, DOGE, DOT, SOL.
                                    </p>
                                  </strong>
                                  <p>
                                    By clicking the "Pay Now" button, you confirm that you have read and understood all the information about the Funded Trading Programs and agree
                                    to the terms and conditions.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className={`tab-content body ${this.state.activeTab === 3 ? "active" : ""}`}>
                        <div className={`tab-pane ${this.state.activeTab === 3 ? "active" : ""}`}>
                          {
                            // currentTitle[0].
                            <div>
                              <h5>
                                {currentTitle && currentTitle.title} ${currentTitle && Number(currentTitle.accountSizeNum).toLocaleString()}{" "}
                              </h5>
                              <h5 className="mb-4">
                                {/* {currentTitle && currentTitle[0].title} {currentTitle && currentTitle[0].accountSizeNum} {" "} */}
                                {this.state.program?.discountPrice ? (
                                  <>
                                    <del>
                                      Price :{" "}
                                      <span className="me-2">
                                        <b>{this.state.program && Number(this.state.program.price).toLocaleString()} USDT</b>
                                      </span>{" "}
                                    </del>
                                    <br />
                                    <>
                                      Discount Price :{" "}
                                      <span className="me-2">
                                        <b style={{ color: "gold" }}>{this.state.program && this.state.program.discountPrice.toLocaleString()} USDT</b>
                                      </span>{" "}
                                    </>
                                  </>
                                ) : (
                                  <>
                                    Price :{" "}
                                    <span className="me-2">
                                      <b>{this.state.program && Number(this.state.program.price).toLocaleString()} USDT</b>
                                    </span>{" "}
                                  </>
                                )}
                                <hr />
                                <>
                                  Payment Provider :{" "}
                                  <span className="me-2">
                                    <b>{this.state.paymentOption.usdt ? "USDT" : "Card Payment"}</b>
                                  </span>{" "}
                                </>
                              </h5>
                              <p className="mb-4">{currentTitle && currentTitle.subtitle}</p>{" "}
                            </div>
                          }
                          <div className="row">
                            {this.state.program !== null &&
                              this.state.program.fundTradingProgramStages.map((stage, index) => (
                                <div key={index} className="plan-features mt-5 col-md-4">
                                  <h3>
                                    {stage.type} {stage.type === "PHASE" ? stage.sequence + 1 : ""}
                                  </h3>
                                  <p>
                                    <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                    <span className="token-stage-bonus">{stage.maxDailyLoss}%</span> <span className="token-stage-cap">Max daily loss</span>
                                  </p>
                                  {stage.maxTotalLoss && (
                                    <p>
                                      <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                      <span className="token-stage-bonus">{stage.maxTotalLoss}%</span> <span className="token-stage-cap">Max loss</span>
                                    </p>
                                  )}
                                  {stage.maxTrailingLoss && (
                                    <p>
                                      <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                      <span className="token-stage-bonus">{stage.maxTrailingLoss}%</span> <span className="token-stage-cap">Max trailing loss</span>
                                    </p>
                                  )}
                                  {stage.profitTarget !== null && (
                                    <p>
                                      <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                      <span className="token-stage-bonus">{stage.profitTarget} %</span> <span className="token-stage-cap">Phase {[index + 1]} target</span>
                                    </p>
                                  )}

                                  {stage.minTradingDays !== null && (
                                    <>
                                      {this.isAddonSelected(ProgramAddonType.NO_MIN_TRADING_DAYS) ? (
                                        <>
                                          <p>
                                            <del>
                                              <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                              <span className="token-stage-bonus">{stage.minTradingDays}</span> <span className="token-stage-cap">Min trading days</span>
                                            </del>
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p>
                                            <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                            <span className="token-stage-bonus">{stage.minTradingDays}</span> <span className="token-stage-cap">Min trading days</span>
                                          </p>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {/* {this.state.program !== null && this.state.program.fundTradingProgramStages.length > 2 && (
                                  <p>
                                    <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                    <span className="token-stage-bonus">
                                      {this.state.program !== null ? this.state.program.fundTradingProgramStages[1].profitTarget : ""}%
                                    </span>{" "}
                                    <span className="token-stage-cap">Phase 2 target</span>
                                  </p>
                                )} */}
                                </div>
                              ))}
                          </div>
                          <hr />
                          {selectedAddons?.length > 0 && (
                            <div className="row mt-4">
                              <div className="col">
                                <div className="product-color">
                                  <h5 className="font-size-15">Addons :</h5>
                                  {selectedAddons.map((item: AddonSelectionItem, key: number) => (
                                    item.selected ?
                                      <span key={key} className="me-3">
                                        <a role="button" className={`${item.selected ? "active" : ""}`}>
                                          <div className="product-color-item border rounded p-5">
                                            {/* <img src="/static/media/img-1.fc0c27967f674639fc3d.png" alt="" className="avatar-md" /> */}
                                            {item.title}
                                          </div>
                                          <p>+ {item.addon.priceChangePercentage} %</p>
                                        </a>
                                      </span> : <></>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        {this.state.activeTab !== 3 ?
                          <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}>
                            <a
                              role="button"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab - 1);
                              }}
                            >
                              Previous
                            </a>
                          </li> : <></>
                        }
                        <li className="next">
                          <a
                            role="button"
                            className={`${this.state.activeTab === 3 ? "submit" : ""}`}
                            onClick={() => {
                              this.toggleTab(this.state.activeTab + 1);

                              if (this.state.activeTab === 3) {
                                this.processBuy();
                              }
                            }}
                          >
                            {buttonText}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment >
    );
  }
}

export default withRouterHook(PricingDetailsPage);
