import { AxiosError } from "axios";
import React from "react";
import { toast } from "react-toastify";
import { AuthCookieManager } from "../lib/AuthCookieManager";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { SignInRequestDto } from "../lib/drivers/dto/auth/SignInRequestDto";
import { SignInResponseDto } from "../lib/drivers/dto/auth/SignInResponseDto";

export interface AppContextEntity {
  // auth
  loadingAuthData: boolean;
  isLoggedIn: boolean;
  signIn(model: SignInRequestDto): void;
  signInSoft(access_token: string, refreshToken: string): void;
  signOut(): void;

  notifyError(msg: string): void;
  notifyInfo(msg: string): void;
  // TODO add getUserBadge
}

export const AppContext = React.createContext<AppContextEntity | null>(null);

export type AppProviderProps = {
  // using `interface` is also ok
  // message: string;
  children: any;
};

type AppProviderState = {
  // auth
  loadingAuthData: boolean;
  // access_token: string;
  isLoggedIn: boolean;
};

export class AppProvider extends React.Component<AppProviderProps, AppProviderState> {
  state: AppProviderState = {
    // auth
    loadingAuthData: false,
    // access_token: "",
    isLoggedIn: false
  };

  // #region constructor
  // #endregion

  // #region React Events
  componentDidMount = async () => {
    await this.setState((prevState) => {
      return {
        loadingAuthData: true
      };
    });

    PortCentralServer.Repo.client.errorHandler = this.handleCentralServerError;

    try {
      const token = AuthCookieManager.getToken();
      if (token) {
        // PortCentralServer.Repo.client.accessToken = token;
        await this.setState((prevState) => {
          return {
            // access_token: token,
            isLoggedIn: true
          };
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      await this.setState((prevState) => {
        return {
          loadingAuthData: false
        };
      });
    }
  };

  componentWillUnmount = () => {};
  // #endregion

  // #region Handlers
  // #endregion

  // #region Auth
  signIn = async (model: SignInRequestDto) => {
    await this.setState((prevState) => {
      return {
        loadingAuthData: true
      };
    });

    try {
      const response: SignInResponseDto = await PortCentralServer.Repo.client.authSignIn(model);

      if (response && response.access_token) {
        AuthCookieManager.createSessionCookies({ token: response.access_token, refreshToken: null });

        // PortCentralServer.Repo.client.accessToken = response.access_token;

        await this.setState((prevState) => {
          return {
            // access_token: response.access_token,
            isLoggedIn: true
          };
        });
      }
    } catch (error) {
      this.notifyError(`Error processing request to central: ${(error as any)?.message}`);
      console.error(error);
    } finally {
      await this.setState((prevState) => {
        return {
          loadingAuthData: false
        };
      });
    }
  };

  signInSoft = async (access_token: string, refreshToken: string) => {
    try {
      AuthCookieManager.createSessionCookies({ token: access_token, refreshToken });

      await this.setState((prevState) => {
        return {
          // access_token: response.access_token,
          isLoggedIn: true
        };
      });
    } catch (error) {
      this.notifyError(`Error processing request to central: ${(error as any)?.message}`);
      console.error(error);
    }
  };

  signOut = async () => {
    AuthCookieManager.removeSessionCookies();

    const token = AuthCookieManager.getToken();

    await this.setState((prevState) => {
      return {
        // access_token: "",
        isLoggedIn: false,
        loadingAuthData: false
      };
    });
  };
  // #endregion

  // #region System Functions
  handleCentralServerError = (error: any) => {
    const axiosError = error as AxiosError;
    if (axiosError != null) {
      let errorMessage: string = "";

      if (axiosError.response && axiosError.response.data) {
        const data = axiosError.response.data as any;
        if (data && data.message) {
          errorMessage = data.message;
        }
      }

      if (errorMessage.length > 0) {
        this.notifyError(`Error processing request to central: ${errorMessage}`);
      } else {
        this.notifyError(`Error processing request to central: ${axiosError.message}`);
      }

      if (axiosError.response) {
        if (axiosError.response.status == 401) {
          AuthCookieManager.removeSessionCookies();
          const event = new CustomEvent("redirectEvent", { detail: "/sign-in" });
          document.dispatchEvent(event);
        }
      }
    }

    console.error(error);
  };
  // #endregion

  // #region Helper functions
  // #endregion

  // #region State Setters
  // #endregion

  // #region functions
  notifyError = (msg: string) => {
    toast.error(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark"
    });
  };

  notifyInfo = (msg: string) => {
    toast.info(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark"
    });
  };
  // #endregion

  render() {
    const contextEntity: AppContextEntity = {
      // auth
      loadingAuthData: this.state.loadingAuthData,
      isLoggedIn: this.state.isLoggedIn,
      signIn: this.signIn,
      signInSoft: this.signInSoft,
      signOut: this.signOut,

      notifyError: this.notifyError,
      notifyInfo: this.notifyInfo
    };

    return <AppContext.Provider value={contextEntity}>{this.props.children}</AppContext.Provider>;
  }
}
