import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { AppContext } from "../../context/AppProvider";
import withRouterHook from "../../withRouterHook";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { LoaderComponent } from "../../components/LoaderComponent";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { UserAffiliatePayoutStatus } from "../../lib/drivers/dto/enums/UserAffiliatePayoutStatus";
import { BaseModal } from "../../components/modals/BaseModal";

export type AffiliatePayoutsPageProps = {};

type AffiliatePayoutsPageState = {
  isLoading: boolean;
  isPayoutDataModalOpen: boolean;
  invitationCode: string;
  network: string;
  address: string;
  userDetails: {
    title: string;
    description: string;
  }[];
  payoutData: {
    status: string;
    network: string;
    address: string;
    datePayment?: string;
  }[];
};

class AffiliatePayoutsPage extends React.Component<AffiliatePayoutsPageProps, AffiliatePayoutsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: AffiliatePayoutsPageState = {
    isLoading: true,
    isPayoutDataModalOpen: false,
    network: "",
    address: "",
    invitationCode: `${window.location.origin}/sign-up`,
    userDetails: [],
    payoutData: []
  };

  async componentDidMount() {
    try {
      const invitationCode = await PortCentralServer.Repo.client.getInvitationCode();
      const invitedUsers = await PortCentralServer.Repo.client.getInvitedUsers();
      const userAffiliatePayouts = [];

      let totalPurchaseShareAmount = 0;

      for (const user of invitedUsers.data) {
        totalPurchaseShareAmount += Number(user.totalPurchaseShareAmount);

        userAffiliatePayouts.push((await PortCentralServer.Repo.client.filterUserAffiliatePayouts({ userAffiliatePayoutId: user.userAffiliatePayoutId })).data[0]);
      }

      const payoutData = userAffiliatePayouts.map((payout: any) => ({
        status: payout.status,
        network: payout.network,
        address: payout.address,
        payoutAmount: payout.payoutAmount,
        datePayment: payout.datePayment ? new Date(payout.datePayment).toLocaleString() : "No payment date."
      }));

      const totalPayoutAmount = userAffiliatePayouts.reduce((sum: number, payout: any) => {
        return sum + (Number(payout.payoutAmount) || 0);
      }, 0);

      this.setState({
        payoutData,
        invitationCode: `${window.location.origin}/sign-up?invitation=${invitationCode}`,
        userDetails: [
          {
            title: "Total Invited Users",
            description: invitedUsers.total.toLocaleString()
          },
          {
            title: "Total Share Amount",
            description: `${totalPurchaseShareAmount.toLocaleString()} USDT`
          },
          {
            title: "Total Payout Recieved",
            description: `${totalPayoutAmount.toLocaleString()} USDT`
          }
        ]
      });
    } catch (error) {
      console.error("Error fetching payout data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handlePayoutDataModalClose = () => {
    this.setState({ isPayoutDataModalOpen: false });
  };

  handlePayoutDataSubmit = async () => {
    const { network, address } = this.state;

    await PortCentralServer.Repo.client.setUserAffiliatePayoutData({ payoutNetwork: network, payoutAddress: address });

    if (network && address) {
      this.setState({
        isPayoutDataModalOpen: false,
        network: "ERC20",
        address: null
      });
    }
  };

  getStatusClass(status: string) {
    switch (status) {
      case UserAffiliatePayoutStatus.PENDING:
        return "text-warning";
      case UserAffiliatePayoutStatus.PROCESSING:
        return "text-primary";
      case UserAffiliatePayoutStatus.COMPLETED:
        return "text-success";
      case UserAffiliatePayoutStatus.UNDER_REVIEW:
        return "text-danger";
      case UserAffiliatePayoutStatus.NOT_SET:
      default:
        return "text-muted";
    }
  }

  PayoutDataModalContent = () => {
    return (
      <div className="align-items-center text-center">
        <h3>Payout Data</h3>

        <div className="form-group mt-3">
          <label>Network</label>
          <select className="form-control" value={this.state.network} onChange={(e) => this.setState({ network: e.target.value })}>
            <option value="ERC20">ERC20</option>
            <option value="TRC20">TRC20</option>
          </select>
        </div>

        <div className="form-group mt-3">
          <label>Address</label>
          <input type="text" className="form-control" value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} placeholder="Enter wallet address" />
        </div>
      </div>
    );
  };

  render() {
    const columns = [
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: string }) => <span className={this.getStatusClass(value)}>{value || "Unknown Status"}</span>
      },
      { Header: "Network", accessor: "network" },
      { Header: "Address", accessor: "address" },
      {
        Header: "Payout Amount (USDT)",
        accessor: "payoutAmount",
        Cell: ({ value }: { value: number }) => value
      },
      { Header: "Date of Payment", accessor: "datePayment" }
    ];

    return (
      <Layout>
        <div className="page-content">
          <div className="container-fluid">
            <BaseModal
              isOpen={this.state.isPayoutDataModalOpen}
              content={<this.PayoutDataModalContent />}
              title={"Payout"}
              onSubmitClick={() => this.handlePayoutDataSubmit()}
              onCloseClick={() => this.handlePayoutDataModalClose()}
            />

            <Breadcrumbs title="Affiliate" breadcrumbItem="Payouts" />
            <Row>
              <Col xl="12">
                {this.state.isLoading ? (
                  <LoaderComponent />
                ) : (
                  <>
                    <Row className="justify-content-center">
                      <Col xl="6" className="text-center">
                        <h5>Your Affiliate Code</h5>
                        <a
                          href={`${this.state.invitationCode}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="form-control text-center"
                          style={{ color: "gold", textDecoration: "underline" }}
                        >
                          {`${this.state.invitationCode}`}
                        </a>
                        <br />
                        <div className="">
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => {
                              navigator.clipboard.writeText(`${this.state.invitationCode}`);
                            }}
                          >
                            <i className="bi bi-clipboard">copy</i>
                          </button>
                          <br />
                          <br />

                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => {
                              this.setState({ isPayoutDataModalOpen: true });
                            }}
                          >
                            <i className="bi bi-clipboard">Edit Payout credentials</i>
                          </button>
                        </div>
                      </Col>
                    </Row>

                    <br />
                    <br />

                    <Row className="justify-content-center">
                      {this.state.userDetails.map((detail, key) => (
                        <Col md="3" key={key}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="fw-medium">{detail.title}</p>
                                  <h4 className="mb-0">{detail.description}</h4>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>

                    <Card>
                      <CardBody>
                        <div className="mb-4 h4 card-title">Affiliate Payouts</div>

                        <Row>
                          <TableContainer columns={columns} data={this.state.payoutData} isGlobalFilter={false} isAddOptions={false} customPageSize={10} />
                        </Row>
                      </CardBody>
                    </Card>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouterHook(AffiliatePayoutsPage);
