import moment from "moment";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { LoaderComponent } from "../../components/LoaderComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { SubscriptionPayoutStatus } from "../../lib/drivers/dto/enums/SubscriptionPayoutStatus";
import { UserSubscriptionPayoutDto } from "../../lib/drivers/dto/subscriptions/UserSubscriptionPayoutDto";
import withRouterHook from "../../withRouterHook";

export type PayoutsPageProps = {};

type PayoutsPageState = {
  isLoading: boolean;
  payoutData: UserSubscriptionPayoutDto[];
};

class PayoutsPage extends React.Component<PayoutsPageProps, PayoutsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: PayoutsPageState = {
    isLoading: true,
    payoutData: []
  };

  async componentDidMount() {
    try {
      const response = await PortCentralServer.Repo.client.filterUserSubscriptionPayouts({});

      if (response) {
        await this.setState({ payoutData: response.data });
      }
    } catch (error) {
      console.error("Error fetching payout data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getStatusClass(status: string) {
    switch (status) {
      case SubscriptionPayoutStatus.PENDING:
        return "text-warning";
      case SubscriptionPayoutStatus.PROCESSING:
        return "text-primary";
      case SubscriptionPayoutStatus.COMPLETED:
        return "text-success";
      case SubscriptionPayoutStatus.UNDER_REVIEW:
        return "text-danger";
      case SubscriptionPayoutStatus.NOT_SET:
      default:
        return "text-muted";
    }
  }

  render() {
    const columns = [
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: string }) => <span className={this.getStatusClass(value)}>{value || "Unknown Status"}</span>
      },
      { Header: "Network", accessor: "network" },
      { Header: "Address", accessor: "address" },
      {
        Header: "Payout Amount (USDT)",
        accessor: "payoutAmount",
        Cell: ({ value }: { value: number }) => value
      },
      {
        Header: "Date of Payment",
        accessor: "dateCreated",
        Cell: ({ value }: { value: Date | null }) => (value ? moment(value).format("YYYY-MM-DD HH:mm") : "")
      },
      {
        Header: "Date of Payment",
        accessor: "datePayment",
        Cell: ({ value }: { value: Date | null }) => (value ? moment(value).format("YYYY-MM-DD HH:mm") : "")
      }
    ];

    return (
      <Layout>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Earns" breadcrumbItem="History" />
            <Row>
              <Col xl="12">
                {this.state.isLoading ? (
                  <LoaderComponent />
                ) : (
                  <Card>
                    <CardBody>
                      <div className="mb-4 h4 card-title">Payout History</div>
                      <TableContainer columns={columns} data={this.state.payoutData} isGlobalFilter={false} isAddOptions={false} customPageSize={10} />
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouterHook(PayoutsPage);
