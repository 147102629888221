import React from "react";
import withRouterHook from "../../withRouterHook";

export type TermsOfConditionsPageProps = {};

type TermsOfConditionsPageState = {};

class TermsOfConditionsPage extends React.Component<TermsOfConditionsPageProps, TermsOfConditionsPageState> {
  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: TermsOfConditionsPageProps, prevState: TermsOfConditionsPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  render() {
    return (
      <React.Fragment>
        <main className="nk-pages">
          <section className="section section-solution bg-black" id="solution">
            <div
              className="container"
              style={{
                border: "1px solid #c1a96c",
                borderRadius: "20px",
                padding: "40px 30px"
              }}
            >
              <div className="section-head text-center wide-auto">
                <h1 className="title" style={{ color: "#c1a96c" }}>
                  Terms of Conditions
                </h1>
                {/* <p style={{fontSize: "18px"}}>Have questions about getting started, learning the ropes, or earning with us? Find all the answers in our FAQ section.</p> */}
              </div>
              <p>
                This website, FALCONE 10X INC (&ldquo;Company&rdquo;, &ldquo;Website&rdquo;, &ldquo;Platform&rdquo;, &ldquo;our&rdquo;, or &ldquo;us&rdquo;), offers you, whether on
                your own or on behalf of any other legal entity (&ldquo;you&rdquo;, &ldquo;User&rdquo;, &ldquo;Client&rdquo;, &ldquo;Trader&rdquo; or &ldquo;Funded Trader&quot;),
                access to substantial trading demo accounts (&ldquo;demo account&rdquo;) at competitive costs. By participating in the BoneX Fund Trading program (the
                &ldquo;Service&rdquo;), Traders can benefit from large account sizes and an efficient evaluation process, which, if successfully completed, provides significant
                capital for live trading. These Terms and Conditions (Terms) govern the use of the Services provided by us, outlining the responsibilities of both the Trader and
                the Company. By registering on our Website, Users agree to be bound by these Terms. Note that this Website offers an interface for account management and monitoring
                of the Trader&rsquo;s progress, including different tools for risk management and calculating the profit targets. Nonetheless, all trading actions are executed on
                the website of BoneX (https://www.bonex.net) (Trading Platform), and you should consider the User Agreement and the Privacy Policy published on this Website.
              </p>
              <p>Please read the Terms completely and carefully.</p>
              <h3>1. Our Platform</h3>
              <p>1.1 BoneX Fund Trading Program</p>
              <p>
                1.1.1 We provide a platform and tools for training in a simulated environment in the cryptocurrency market. Accordingly, we offer an opportunity for Traders to
                train and apply their financial knowledge by operating from demo accounts.
              </p>
              <p>
                1.1.2 You must first pass our ONE or TWO-phase evaluation process before you can acquire the right to withdraw any of your profit, based on the knowledge and skills
                acquired.
              </p>
              <p>1.1.3 You acknowledge that any operation you perform through our Service is not real, based on a mere simulated environment.</p>
              <p>1.2 Third-Party Services</p>
              <p>
                You acknowledge and accept that the Company provides access to third-party applications. You consent that you are account details might be shared with third
                parties.
              </p>
              <p>1.3 Amendments to the Conditions</p>
              <p>
                The Company reserves the right to modify or amend these Terms at any time. You shall be bound by any such changes. No express notification shall be required for
                establishing the binding nature of the amended Terms. You bear the obligation to regularly check the latest version of these Terms. Any and all modifications or
                changes to these Terms will become effective upon publication on the Website. Your continued use of the Website after the date on which such amended Terms are
                posted shall be construed as an indication that you have familiarized yourself with and have accepted the changes in any amended Terms. If you do not agree with any
                of the posted changes, you must immediately cease using the Company&rsquo;s platform, the Company&rsquo;s Services, and/or the Company&rsquo;s Tools.
              </p>
              <p>1.4 Privacy</p>
              <p>
                Your use of bonex.fund or bonex.net is subject to the Terms of our Privacy Policy. Please review our Privacy Policy, which also governs the site and informs Users
                of our data collection practices.
              </p>
              <p>1.5 Electronic Communication</p>
              <p>
                Visiting bonex.fund or bonex.net or sending us emails constitutes electronic communications. You consent to receive electronic communications and agree that all
                agreements, notices, disclosures, and other communications that we provide to you electronically, via email, and on the Site, satisfy any legal requirement that
                such communications be in writing.
              </p>
              <h3>2. Accessibility</h3>
              <p>
                You understand and acknowledge that occasionally the Company&rsquo;s platform may be inaccessible or inoperable for any reason, including, without limitation, due
                to: i. Equipment malfunctions; ii. Periodic maintenance procedures or repairs that the Company may carry out occasionally; or iii. Causes beyond the Company&rsquo;s
                control or that are not reasonably foreseeable by the Company.
              </p>
              <h3>3. Account Management</h3>
              <p>3.1 Account Registration</p>
              <p>
                3.1.1 To participate in BoneX Fund Trading, you must register an account through the Company&rsquo;s application. When you purchase one of our demo accounts, you
                will receive credentials to trade on the BoneX Fund Trading Platform. Credentials will be set within one business day."
              </p>
              <p>3.1.2 You may not share your password with anyone except authorized employees.</p>
              <p>
                3.1.3 You must always provide accurate, current, and complete information and must update such information in a timely manner to maintain its accuracy and
                completeness. Providing false information of any kind will result in the cancellation or rejection of your account.
              </p>
              <p>3.1.4 Any use of the site through your account will be deemed to have been used by you.</p>
              <p>3.1.5 The Company has the right to rely on the contact and other information provided to us through your Account.</p>
              <p>3.1.6 Your Account is NON-TRANSFERABLE and NON-ASSIGNABLE.</p>
              <p>3.1.7 We are not responsible for third-party access to and from your account resulting from theft or misappropriation of your account.</p>
              <p>
                3.1.8 We and our associates reserve the right to refuse or cancel Service, terminate accounts, or remove or edit content at our sole discretion, without providing
                any reason and with immediate effect.
              </p>
              <p>3.2 Setup</p>
              <p>3.2.1 Once your registration is complete, you can select either a one-phase or two-phase evaluation process, along with the account size.</p>
              <p>3.2.2 After placing your order and paying the one-time fee, you will receive your credentials via the platform.</p>
              <p>
                3.2.3 Trading is possible starting the day after the activation is completed, at 0:00 UTC. This applies both to the initial activation and to entering Phase 2 of
                the two-phase evaluation process. Please note that the monitoring system takes into account only full days.
              </p>
              <p>
                3.2.4 It is strictly prohibited to lend or receive services from/for third parties of any kind, whether to boost accounts, offer benefits in exchange for accounts,
                profits, or any benefit from the site. This will be penalized with the suspension of all active accounts of both parties.
              </p>
              <h3>4. Non-Permitted Usage / Usage by Minors</h3>
              <p>
                Subject to the age of majority in your jurisdiction, generally, the Company&rsquo;s platform is for people over 18 years old. If you are under 18 years old, you are
                not permitted to use this site.
              </p>
              <h3>5. Fees and Other Payments</h3>
              <p>
                5.1 You must pay a one-time fee to access the demo account. The funds provided to you during Phase 1 or Phase 2 are demo funds. These funds are not real funds and
                cannot be used for real operations. The profits you generate through our &quot;Phase 1&quot;, and/or &quot;Phase 2&quot; levels are not real profits, you are not
                entitled to those profits, nor can you request a withdrawal during this period; regardless of whether you have opted for the evaluation of 1 or 2 phases.
              </p>
              <p>5.3 The fees for our Services are denominated ONLY in USDT and you can pay ONLY using cryptocurrency.</p>
              <p>5.4 Service charges include the corresponding taxes.</p>
              <p>5.5 Fees for account tiers can be found on our Website.</p>
              <p>
                5.6 The one-time payment for account activation is non-refundable under any circumstances, except as required by law. Users must ensure they are prepared to abide
                by the rules and complete the evaluation process before making any payments.
              </p>
              <h3>6. Rules of Demo Trading</h3>
              <p>
                During demo trading on the Trading Platform, you may carry out any transaction unless they constitute prohibited trading strategies or operations as defined in
                Section 7. Restrictions may also be imposed by the conditions of the Trading Platform.
              </p>
              <p>
                You acknowledge that we have access to information about the demo operations you perform on the Trading Platform. You authorize us to share this information with
                persons/entities that are part of a group with us or that are otherwise affiliated with us, and you grant us and these persons/entities your consent and
                authorization to handle this information at their free will. You agree that these activities are carried out automatically without any kind of consent,
                consultation, or approval on your part and that you have no right to any compensation or income associated with the use of this information.
              </p>
              <h3>7. Prohibited Trading Strategies or Transactions</h3>
              <p>
                {" "}
                &bull; It is expressly prohibited to use the Services to violate any law, statute, ordinance, regulation, or treaty, whether local, state, provincial, national, or
                international, or to infringe the rights of a third party, including, among others, intellectual property rights, privacy rights, publicity rights, or other
                personal or property rights. &bull; In addition, it is expressly prohibited to scrape, crawl, frame, post unauthorized links, aggregate, hack, carry out
                denial-of-service attacks, reverse engineer, or circumvent the technological protection measures of the Services or the Website. &bull; It is also prohibited to use
                the Services or the Company&rsquo;s website to transmit unsolicited commercial products or Services to third parties or to company merchants. Although the Company
                is not responsible for any content posted by its merchants and has no obligation to monitor such content, it reserves the right to remove any such content from any
                company communication channel. &bull; Attempting to arbitrage your account with another Company or third-party account, as determined by the Company at its sole and
                absolute discretion. &bull; The use of trading strategies that are difficult to replicate in the real market or come with a higher risk when trying to do so. This
                includes, but is not limited to, operations that use all the available margin for individual positions, operations that result in excessively large fluctuations in
                unrealized gains/losses, etc. &bull; Executing trade ideas that belong to or are inspired by third parties, including, among other things, copying ideas from other
                traders and analysts and copying trade signals from any type of trading community, social networks, research reports, crowdsourcing ideas, etc. &bull; Sharing
                accounts of any nature, including but not limited to sharing credentials with other Users and/or operating multiple accounts from the same household, devices, or IP
                address/VPS. The User is responsible for the risk of using VPNs, as these could lead to IP matches with other Users. &bull; The Trader declares, warrants, and
                agrees not to make any public statement in any type of social media or public access medium disputing, challenging, or criticizing in any way the application by the
                BoneX Fund Trading Account. &bull; Contradicting these Terms. &bull; Trading with a purpose that contradicts the way trading is carried out in the real market or in
                a manner that the Company may suffer losses or damages as a result of the Trader&rsquo;s activity. &bull; Obtaining all the profit in a single trade. Once the
                capital reaches $10,000 of profit per day and/or per trade, active trades will be closed, and the amount that exceeds $10,000 will be deducted. The difference will
                be calculated based on the balance at 12:05 AM UTC. Any operation closed in several parts or several operations executed at a similar time counts as a single
                operation. &bull; Hedging or reverse trading. &bull; We are a trading Company, so we do not allow, in any case, non-investment, i.e., gambling; the company reserves
                the right to decide on such consideration. &bull; Any disrespect by the User to the Company through any communication channel will result in the indefinite
                suspension of the User. &bull; Opening orders on any protocol other than the following will result in account suspension: ETH, BTC, LTC, XRP, ADA, DOGE, DOT, SOL. &bull; Hitting the target profit in 1 trade is not allowed.
              </p>
              <p>
                If the Company determines, at its discretion, that your operations constitute the aforementioned Prohibited Operations, or that they violate the Risk Management and
                Program Rules, or that your public statements violate these Terms, your participation will be terminated, which may include the loss of the established returns.
              </p>
              <p>If you engage in any of the practices described above:</p>
              <p>
                {" "}
                &bull; The Company may consider it a breach of the conditions if you carry out any of these practices. The Provider may consider it a violation of our rules and
                will remove the demo account provided to you, and you will be expelled from the program. &bull; If you have carried out any of the transactions listed in section
                1.1, the Provider reserves the right to suspend the account without prior notice. In such a case, you will not be entitled to a refund of the fees paid.
              </p>
              <h3>8. BoneX Fund Trading Evaluation Process</h3>
              <p>In order for you to meet our conditions of the Evaluation Process, you must fulfill all of the following parameters:</p>
              <p>One-Phase Evaluation</p>
              <p>
                {" "}
                &bull; Risk management rules: At no time during the One-Phase evaluation have you registered losses in any open or closed demo trades on the same day, which
                exceeded in total 4% of your balance at 12:05 AM UTC or 6% of the initial demo balance. &bull; Profit target: You have reached a profit target of 10%.
              </p>
              <p>Two-Phase Evaluation</p>
              <p>
                {" "}
                &bull; At no time during the Two-Phase evaluation have you registered losses in any open or closed demo trades on the same day, which exceeded in total 5% of your
                balance at 12:05 AM UTC or 10% of the initial demo balance. &bull; Profit target: You have reached a Phase 1 profit target of 8%, followed by a Phase 2 target of
                5%.
              </p>
              <h3>9. BoneX Fund Trading &ldquo;Funded Trader&quot;</h3>
              <p>
                Upon successfully completing the evaluation process, Users will progress to the live trading phase. During this phase, Traders must continue to adhere to the
                Company&rsquo;s risk management protocols. Should the Trader meet the profit criteria set forth in the live trading phase, they are entitled to withdraw up to 90%
                of their profits. Upon requesting the withdrawal, we may lock the account for a period of up to 3 days to examine and confirm the successful completion of the
                evaluation criteria. Withdrawals can only be executed after all performance and risk criteria are met, as determined by BoneX Funded Trading&rsquo;s monitoring
                system.
              </p>
              <p>9.1 &ldquo;Funded Trader&rdquo; Copy Trading</p>
              <p>
                Once we have confirmed that the Funded Trader is profitable according to our criteria, we can move on to include the conditions set by the current provider at the
                time we want to do copy trading.
              </p>
              <h3>10. Duration</h3>
              <p>The rights and obligations arising from these conditions will last as long as the User&rsquo;s active trading accounts.</p>
              <h3>11. Disputes</h3>
              <p>
                The parties are encouraged to attempt to resolve disputes amicably. If for any reason this fails, the Courts of the Republic of Panama are established as the
                dispute forum, with the express waiver of any other jurisdiction.
              </p>
              <h3>12. Limitation of Liability and Indemnification</h3>
              <p>
                The information and materials on this Website are provided as general information only and have not been prepared to take into account your particular objectives,
                financial situation, or needs, and are not suitable to be relied upon as personal financial product advice. Therefore, before acting on the basis of the information
                and materials on this site, you should consider whether the information and materials are appropriate for you, having regard to your objectives, financial
                situation, and needs. BoneX Fund Trading makes no warranties or representations about the Services and any content available on the Website, including, but not
                limited to, the accuracy, reliability, completeness, timeliness, or reliability thereof. The Company will not be subject to liability for the truth, accuracy, or
                completeness of any content or any other information conveyed to any User, or for errors, mistakes, or omissions therein, or for any delays or interruptions of the
                data or information stream from whatever cause. To the fullest extent permitted by law, as a User, you agree that you use the Services and any content at your own
                risk.
              </p>
              <p>
                BoneX Fund Trading does not warrant that the products on the Website will operate error-free or that the Service and any content are free of computer viruses or
                similar contamination or destructive features. If your use of the Website or any content results in the need for servicing or replacing equipment or data, the
                Company will not be responsible for those costs. BoneX Fund Trading is not liable for any damages, including, without limitation, damage to your computer or
                computer system or settings, loss of data, revenue, or profits, which you may suffer arising out of your use, delay in using, or inability to access this Website,
                or in any other way connected with this Website, including from the downloading of any software from this Website.
              </p>
              <p>This limitation applies to the extent permitted by law.</p>
              <h3>13. Disclaimer</h3>
              <p>
                13.1 BoneX Fund Trading does not offer financial Services, nor financial, tax, brokerage, or other advice. You also acknowledge that we do not accept any deposits.
                We only charge fees for purchasing the demo accounts, for the settlement of costs and/or operational expenses of personnel and technology, for the proper
                functioning of our Services.
              </p>
              <p>
                13.2 FALCONE 10X INC does not provide any of the investment services listed in the capital markets law and only uses a demo version of investment scenarios, so it
                does not guarantee the Users the possibility of withdrawing any of their profits.
              </p>
              <p>13.3 The content of our Platform is applicable to the extent that local laws and/or regulations allow.</p>
              <p>
                13.4 All information on the Platform is provided &quot;as is,&quot; without warranty of completeness, accuracy, timeliness, or of the results obtained from the use
                of this information, and without warranty of any kind, express or implied, including, but not limited to, warranties of performance, merchantability, and fitness
                for a particular purpose.
              </p>
              <h3>14. Intellectual Property</h3>
              <p>
                14.1 The Company&rsquo;s Services and the content of the Platform (excluding Content provided by you or other Users), features, and functionality are and will
                remain the exclusive property of the Company and its licensors.
                <br /> 14.2 The Company&rsquo;s trademarks may not be used in connection with any Service without the prior written consent of the Company.
                <br /> 14.3 The Company is protected by copyright, trademark, and other laws of both the Country and foreign countries.
                <br /> 14.4 The Company also owns all transaction information and any other information related to orders, including, among others, the information that is created
                after a transaction, and the ratings and reviews provided by the Clients. All this information is subject to any existing Privacy Policy on the Company&rsquo;s
                Platform.
              </p>
              <h3>15. Governing Law</h3>{" "}
              <p>
                <br />
                15.1 These Terms shall be governed and construed in accordance with the laws of the United Arab Emirates, specifically the Republic of Panama, without regard to its
                conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these
                Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire Agreement
                between us regarding the use of the Website and supersede and replace any prior agreements the Parties might have had in this regard.
                <br /> 15.2 If you access the Site from anywhere in the world, you will be responsible for compliance with all local laws. You agree that you will not use our
                Content accessed through bonex.fund or bonex.net in any country or in any manner prohibited by applicable laws, restrictions, or regulations.
              </p>
              <h3>16. Termination</h3>{" "}
              <p>
                16.1 We reserve the right, at our sole discretion, to terminate your access to the Website and the related Services or any portion thereof at any time, without
                notice.
                <br /> 16.2 You will continue to be bound by these Terms, even if your account is canceled. Specifically, any of your obligations related to the limitations of
                liability and indemnification, as well as any other obligation under this Agreement that by its nature is intended to survive, including any obligation of payment
                or Services, will survive the termination of this Agreement.
                <br /> 16.3 The termination of this Agreement shall be without prejudice to the rights of either Party against the other in connection with any claim prior to or
                breach of any of the provisions of this Agreement.
              </p>
              <h3>17. Entirety </h3>
              <p>These Terms and the Privacy Policy represent the entire Agreement between both Parties and supersede any prior written or oral agreement.</p>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default withRouterHook(TermsOfConditionsPage);
