import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/bull-logo.png";
import favico from "../assets/images/favicon.png"
import SidebarContent from "./SidebarContent";

// Props & State
export type SidebarProps = {
  theme: string;
  type: string;
  isMobile: boolean;
};

type SidebarState = {};

export class Sidebar extends React.Component<SidebarProps, SidebarState> {
  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={favico} alt="BoneX Fund" height="23" />
              </span>
              <span className="logo-lg">
                <img src={logo} alt="BoneX Fund" height="45" />
              </span>
            </Link>
          </div>
          <div data-simplebar className="h-100">
            <SidebarContent />
          </div>
          <div className="sidebar-background"></div>
        </div>
      </React.Fragment>
    );
  }
}
