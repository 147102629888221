import "flatpickr/dist/themes/material_blue.css";
import moment from "moment";
import React from "react";
import Flatpickr from "react-flatpickr";
import { useLocation } from "react-router-dom";
import { CardBody, Col, Row } from "reactstrap";
import ApexRadial from "../../components/Common/ApexRadial";
import { LoaderComponent } from "../../components/LoaderComponent";
import { BaseModal } from "../../components/modals/BaseModal";
import PagerComponent from "../../components/PagerComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { BaseFilter } from "../../lib/core/filters/BaseFilter";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { StageType } from "../../lib/drivers/dto/enums/StageType";
import { SubscriptionIndexFlagType } from "../../lib/drivers/dto/enums/SubscriptionIndexFlagType";
import { SubscriptionStatus } from "../../lib/drivers/dto/enums/SubscriptionStatus";
import { ViewUserSubscriptionProgressInfoDto } from "../../lib/drivers/dto/subscriptions/ViewUserSubscriptionProgressInfoDto";
import withRouterHook from "../../withRouterHook";

export type AccountsPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type Filter = BaseFilter & {
  fundTradingProgramId?: string;
  status?: SubscriptionStatus;
};

type AccountsPageState = {
  isLoading: boolean;
  list: ViewUserSubscriptionProgressInfoDto[];
  filter: Filter;
  isActivationModalOpen: boolean;
  isCancelModalOpen: boolean;
  isCredentialsModalOpen: boolean;
  isClaimModalOpen: boolean;
  subscriptionToActivate: string;
  subscriptionToCancel: string;
  subscriptionToPayout: string;
  activationDate: Date;
  accountDetails: {
    username: string;
    password: string;
    mfa: string;
  };
  payoutAmount: number;
  network: string;
  address: string;
};

class AccountsPage extends React.Component<AccountsPageProps, AccountsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: AccountsPageState = {
    isLoading: true,
    list: [],
    filter: { page: 1, pageSize: 128, total: 0 },
    isActivationModalOpen: false,
    isCancelModalOpen: false,
    isCredentialsModalOpen: false,
    isClaimModalOpen: false,
    subscriptionToActivate: null,
    subscriptionToCancel: null,
    subscriptionToPayout: null,
    activationDate: null,
    accountDetails: { username: null, password: null, mfa: null },
    payoutAmount: 0,
    network: "ERC20",
    address: ""
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const queryParams = new URLSearchParams(this.props.location.search);

    const filter: Filter = {
      fundTradingProgramId: queryParams.get("program") || "",
      status: (queryParams.get("status") as SubscriptionStatus) || null,

      page: parseInt(queryParams.get("page") || "1", 10),
      pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
      total: 0
    };

    const offset = (filter.page - 1) * filter.pageSize;

    const response = await PortCentralServer.Repo.client.filterUserSubscriptions({
      fundTradingProgramId: filter.fundTradingProgramId,
      status: filter.status,
      limit: filter.pageSize,
      offset: offset
    });

    if (response) {
      filter.total = response.total;

      const statusPriority: { [key in SubscriptionStatus]: number } = {
        [SubscriptionStatus.ACTIVE]: 1,
        [SubscriptionStatus.READY]: 2,
        [SubscriptionStatus.DRAFT]: 3,
        [SubscriptionStatus.FINISHED]: 4,
        [SubscriptionStatus.FAILED]: 5,
        [SubscriptionStatus.NOT_SET]: 6,
        [SubscriptionStatus.PAID]: 7
      };

      response.data.map((subscription) => subscription.fundTradingProgramStageSequence++);

      this.setState(() => {
        return {
          filter: filter,
          list: response.data
            .filter((userSubscription) => !userSubscription.userSubscriptionDeletedYn)
            .sort((a, b) => {
              // Compare based on status priority
              const statusOrderA = statusPriority[a.userSubscriptionStatus];
              const statusOrderB = statusPriority[b.userSubscriptionStatus];

              if (statusOrderA !== statusOrderB) {
                return statusOrderA - statusOrderB;
              }

              // If statuses are the same, compare by startDate in descending order
              const startDateA = new Date(a.userSubscriptionDateStart).getTime();
              const startDateB = new Date(b.userSubscriptionDateStart).getTime();

              return startDateB - startDateA; // Descending order
            }),
          isLoading: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: AccountsPageProps, prevState: AccountsPageState) => { };

  componentWillUnmount = async () => {
    // destroy connection
  };

  calculateProgress = (realizedPnl: number, profitTargetPercantage: number, accountSize: number) => {
    const profitTargetAmount = (profitTargetPercantage / 100) * accountSize;
    const progress = (realizedPnl / profitTargetAmount) * 100;

    if (progress > 100) {
      return 100;
    } else if (progress < 0 || !progress) {
      return 0;
    }

    return Math.round(progress);
  };

  submitPaymentForm(vendor: string, amount: number, symbol: string, inv: string, email: string): void {
    // Create a blurred background overlay
    const overlay = document.createElement("div");
    overlay.style.position = "fixed";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.width = "100vw";
    overlay.style.height = "100vh";
    overlay.style.backgroundColor = "transparent"; // Semi-transparent black background
    overlay.style.backdropFilter = "blur(5px)"; // Apply blur effect to the background
    overlay.style.zIndex = "999"; // Ensure the overlay is on top
    document.body.appendChild(overlay);

    // Create the iframe
    const iframe = document.createElement("iframe");
    iframe.name = "paymentFrame";
    iframe.style.overflowY = "hidden"; // Enable vertical scrolling inside the iframe
    iframe.style.overflowX = "hidden";
    iframe.style.position = "fixed";
    iframe.style.top = "50%";
    iframe.style.left = "50%";
    iframe.style.transform = "translate(-50%, -50%)"; // Center the iframe

    // Set responsive width/height for mobile
    iframe.style.width = "80vw"; // 90% of viewport width
    iframe.style.height = "80vh"; // 80% of viewport height
    iframe.style.border = "1px solid black";
    iframe.style.borderRadius = "10px"; // Rounded corners for better UX
    iframe.style.overflow = "auto"; // Add scroll if content overflows
    iframe.style.zIndex = "1000"; // Ensure the iframe is above the overlay
    document.body.appendChild(iframe);

    // Create the close button
    const closeButton = document.createElement("button");
    closeButton.innerText = "X";
    closeButton.style.position = "fixed";
    closeButton.style.top = "calc(50% - 37vh)"; // Adjust relative to iframe height
    closeButton.style.left = "calc(50% + 40vw - 25px)"; // Adjust relative to iframe width
    closeButton.style.transform = "translate(-50%, -50%)";
    closeButton.style.backgroundColor = "#000";
    closeButton.style.color = "#fff";
    closeButton.style.border = "none";
    closeButton.style.padding = "10px";
    closeButton.style.fontSize = "18px";
    closeButton.style.cursor = "pointer";
    closeButton.style.zIndex = "1001"; // Ensure the button is on top
    document.body.appendChild(closeButton);

    // Handle closing the iframe and overlay
    closeButton.onclick = () => {
      document.body.removeChild(iframe);
      document.body.removeChild(overlay);
      document.body.removeChild(closeButton);
    };

    // Create the form and set its target to the iframe
    const form = this.createPaymentForm(vendor, amount, symbol, inv, email);
    form.target = "paymentFrame";
    document.body.appendChild(form);

    // Submit the form
    form.submit();
  }

  createPaymentForm(vendor: string, amount: number, symbol: string, inv: string, email: string): HTMLFormElement {
    const form = document.createElement("form") as HTMLFormElement;
    form.method = "POST";
    form.action = "https://payment.bonex.fund/order_popup";
    form.target = "paymentFrame"; // Set the target to the iframe

    form.appendChild(this.createHiddenInput("vendor", vendor));
    form.appendChild(this.createHiddenInput("amount", amount.toString()));
    form.appendChild(this.createHiddenInput("symbol", symbol));
    form.appendChild(this.createHiddenInput("inv", inv));
    form.appendChild(this.createHiddenInput("email", email));

    return form;
  }

  createHiddenInput(name: string, value: string): HTMLInputElement {
    const input = document.createElement("input") as HTMLInputElement;
    input.type = "hidden";
    input.name = name;
    input.value = value;
    return input;
  }

  payUserSubscription = (userSubscription: ViewUserSubscriptionProgressInfoDto) => {
    if (userSubscription.fundTradingProgramPrice && userSubscription.userSubscriptionPurchaseId && userSubscription.userEmail) {
      this.submitPaymentForm("123456789abcdef", userSubscription.fundTradingProgramPrice, "USDT", userSubscription.userSubscriptionPurchaseId, userSubscription.userEmail);
    }
  };

  handleDateSubmit = async () => {
    if (this.state.activationDate && this.state.subscriptionToActivate) {
      await PortCentralServer.Repo.client.activateSubscription({
        userSubscriptionId: this.state.subscriptionToActivate,
        dateStart: this.state.activationDate
      });

      window.location.reload();
    }

    this.setState({ isActivationModalOpen: false, subscriptionToActivate: null });
  };

  handleActivationModalClose = () => {
    this.setState({ isActivationModalOpen: false, subscriptionToActivate: null });
  };

  handleClaimModalClose = () => {
    this.setState({ isClaimModalOpen: false });
  };

  handleClaimSubmit = async () => {
    if (this.state.network && this.state.address) {
      await PortCentralServer.Repo.client.payoutSubscription({
        userSubscriptionId: this.state.subscriptionToPayout,
        network: this.state.network,
        address: this.state.address
      });

      await this.setState({
        isClaimModalOpen: false,
        network: "ERC20",
        address: ""
      });
    }
  };

  activateUserSubscription = async (userSubscriptionId: string) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    this.setState({
      isActivationModalOpen: true,
      subscriptionToActivate: userSubscriptionId,
      activationDate: tomorrow
    });
  };

  fetchAccountDetails = async (userSubscriptionId: string) => {
    const credentials = await PortCentralServer.Repo.client.getCredentials(userSubscriptionId);

    if (credentials) {
      this.setState({
        accountDetails: {
          username: credentials.username,
          password: credentials.password,
          mfa: credentials.mfa
        },
        isCredentialsModalOpen: true
      });
    }
  };

  displayActionButtons = (userSubscription: ViewUserSubscriptionProgressInfoDto) => {
    const buttons = [];

    if (userSubscription.userSubscriptionStatus === SubscriptionStatus.PAID) {
      buttons.push(<p className="text-info">Activating your account!</p>);
    }

    // Cancel button
    if (userSubscription.userSubscriptionStatus === SubscriptionStatus.DRAFT) {
      buttons.push(
        <button
          key="cancel"
          onClick={() => this.setState({ isCancelModalOpen: true, subscriptionToCancel: userSubscription.userSubscriptionId })}
          className="btn-rounded btn btn-warning me-2"
        >
          Cancel
        </button>
      );
    }

    // DRAFT status: Pay and Cancel buttons
    // if (userSubscriptionStatus === SubscriptionStatus.DRAFT) {
    //   buttons.push(
    //     <button key="pay" onClick={() => this.payUserSubscription(userSubscription)} className="btn-rounded btn btn-success me-2">
    //       Pay
    //     </button>
    //   );
    // }

    // READY status: Activate button
    if (userSubscription.userSubscriptionStatus === SubscriptionStatus.READY) {
      buttons.push(
        <button key="activate" onClick={() => this.activateUserSubscription(userSubscription.userSubscriptionId)} className="btn-rounded btn btn-success me-2">
          Activate
        </button>
      );
    }

    // FINISHED status: Payout and Progress buttons
    if (userSubscription.fundTradingProgramStageType === StageType.LIVE) {
      if (userSubscription.currentStageIndexAllowProfitClaimYn) {
        buttons.push(
          <button
            key="payout"
            onClick={() => {
              this.setState({
                isClaimModalOpen: true,
                payoutAmount: userSubscription.currentStageIndexProfitSplitAmount || 0,
                subscriptionToPayout: userSubscription.userSubscriptionId
              });
            }}
            className="btn-rounded btn btn-success me-2"
          >
            Payout
          </button>
        );
      }
    }

    // ACTIVE or FAILED status: Progress button
    if (userSubscription.userSubscriptionStatus === SubscriptionStatus.ACTIVE || userSubscription.userSubscriptionStatus === SubscriptionStatus.FAILED) {
      buttons.push(
        <button
          key="progress"
          onClick={() => window.location.replace(`account-progress/${userSubscription.userSubscriptionId}/${userSubscription.currentStageIndexId}`)}
          className="btn-rounded btn btn-info me-2"
        >
          Progress
        </button>
      );
    }

    // Return the array of buttons
    return buttons;
  };

  confirmCancel = async () => {
    if (this.state.subscriptionToCancel) {
      const userDeleted = await PortCentralServer.Repo.client.cancelUserSubscriptions(this.state.subscriptionToCancel);

      this.setState({
        isCancelModalOpen: false,
        subscriptionToCancel: null
      });

      if (userDeleted) {
        window.location.reload();
      }
    }
  };

  cancelModalClose = () => {
    this.setState({
      isCancelModalOpen: false,
      subscriptionToCancel: null
    });
  };

  handleCredentialsModalClose = () => {
    this.setState({
      isCredentialsModalOpen: false,
      accountDetails: { username: null, password: null, mfa: null }
    });
  };

  DateTimePickerContent = () => {
    return (
      <div className="align-items-center text-center">
        <h3>Select Subscription Activation Date</h3>
        <Flatpickr
          className="form-control d-block align-items-center text-center"
          placeholder="Select your desired activation date:"
          options={{
            inline: true,
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "Y-m-d H:i",
            minDate: this.state.activationDate,
            onChange: (date) => {
              this.setState({ activationDate: date[0] });
            }
          }}
        />
      </div>
    );
  };

  ClaimContent = () => {
    return (
      <div className="align-items-center text-center">
        <h3>Claim Payout</h3>
        <div className="form-group mt-3">
          <label>Payout Amount</label>
          <input disabled={true} type="text" className="form-control" value={`${this.state.payoutAmount} USDT`} placeholder="Enter payout amount" />
        </div>

        <div className="form-group mt-3">
          <label>Network</label>
          <select className="form-control" value={this.state.network} onChange={(e) => this.setState({ network: e.target.value })}>
            <option value="ERC20">ERC20</option>
            <option value="TRC20">TRC20</option>
          </select>
        </div>

        <div className="form-group mt-3">
          <label>Address</label>
          <input type="text" className="form-control" value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} placeholder="Enter wallet address" />
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <BaseModal
              isOpen={this.state.isActivationModalOpen}
              content={<this.DateTimePickerContent />}
              title={"Choose subscription activation date"}
              onSubmitClick={() => this.handleDateSubmit()}
              onCloseClick={() => this.handleActivationModalClose()}
            />
            <BaseModal
              isOpen={this.state.isCancelModalOpen}
              content={<h6>Are you sure you want to cancel this subscription?</h6>}
              title="Confirm Cancellation"
              onSubmitClick={this.confirmCancel}
              onCloseClick={this.cancelModalClose}
              isWarning={true}
            />
            <BaseModal
              isOpen={this.state.isCredentialsModalOpen}
              content={
                <div>
                  <strong>
                    <p>Add 2FA via Google Authenticator App using MFA code below.</p>
                    <p>
                      Login into <a href="https://www.bonex.net/en_US/">bonex.net</a> using the username, password and 2FA code.
                    </p>
                  </strong>
                  <p>
                    <strong>Username:</strong> {this.state.accountDetails.username}
                  </p>
                  <p>
                    <strong>Password:</strong> {this.state.accountDetails.password}
                  </p>
                  <p>
                    <strong>MFA:</strong> {this.state.accountDetails.mfa}
                  </p>
                </div>
              }
              title="Account Details"
              onSubmitClick={this.handleCredentialsModalClose}
              onCloseClick={this.handleCredentialsModalClose}
            />
            <BaseModal
              isOpen={this.state.isClaimModalOpen}
              content={<this.ClaimContent />}
              title={"Payout"}
              onSubmitClick={() => this.handleClaimSubmit()}
              onCloseClick={() => this.handleClaimModalClose()}
            />

            <div className="container-fluid">
              <Breadcrumbs title="Account" breadcrumbItem="Subscriptions" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent />
                  ) : (
                    <>
                      <div>
                        <CardBody>
                          {/* <Form method="get">
                            <Row className="mb-4">
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Level</Label>
                                  <EnumSelectControl
                                    enumData={SubscriptionStatus}
                                    name="type"
                                    selectedValue={this.state.filter.status}
                                    className="form-control select2-search-disable"
                                  />
                                </FormGroup>
                              </Col>
                              <div className="col-xl col-sm-6 align-self-end">
                                <div className="mb-3">
                                  <input type="hidden" name="page" value={this.state.filter.page} />
                                  <input type="hidden" name="pageSize" value={this.state.filter.pageSize} />
                                  <Button type="submit" color="primary" className="w-md">
                                    Filter
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </Form> */}

                          <Row>
                            <Col xl={12} className="">
                              <div className="table-responsive scroll-horizontal-hidden">
                                <Row>
                                  {(this.state.list || []).map((item: ViewUserSubscriptionProgressInfoDto, key: number) => (
                                    <Col xl={3} key={key}>
                                      <div className="card account-card">
                                        <div className="card-body text-center">
                                          <div className="d-flex flex-column align-items-center">
                                            <h5 className="text-truncate font-size-15 mb-1">
                                              <p className="text-dark">
                                                {item.fundTradingProgramTitle}: ${Number(item.fundTradingProgramAccountSize).toLocaleString()}
                                              </p>
                                            </h5>
                                            <div className="d-flex flex-column align-items-center mt-2">
                                              <span
                                                className={`badge rounded-pill font-size-12 ${item.userSubscriptionStatus === SubscriptionStatus.ACTIVE || item.userSubscriptionStatus === SubscriptionStatus.PAID
                                                  ? "badge-soft-success"
                                                  : item.userSubscriptionStatus === SubscriptionStatus.DRAFT
                                                    ? "badge-soft-info"
                                                    : item.userSubscriptionStatus === SubscriptionStatus.READY
                                                      ? "badge-soft-primary"
                                                      : item.userSubscriptionStatus === SubscriptionStatus.FINISHED
                                                        ? "badge-soft-info"
                                                        : "badge-soft-danger"
                                                  }`}
                                                id="task-status"
                                              >
                                                {item.userSubscriptionStatus === SubscriptionStatus.DRAFT ? "PROCESSING" : item.userSubscriptionStatus}
                                              </span>
                                              {(item.userSubscriptionStatus === SubscriptionStatus.ACTIVE && item.currentStageIndexDateFrom && moment().utc().isAfter(moment(item.currentStageIndexDateFrom))) ? (
                                                <span className="btn btn-link" onClick={() => this.fetchAccountDetails(item.currentStageIndexUserSubscriptionCredentialId)}>
                                                  View Account Details
                                                </span>
                                              ) : (
                                                <span className="btn btn-link visibility-hidden">View Account Details</span>
                                              )}
                                              <ApexRadial
                                                progress={this.calculateProgress(
                                                  item.currentStageIndexTotalRealizedPnl,
                                                  item.fundTradingProgramStageProfitTarget,
                                                  item.fundTradingProgramAccountSize
                                                )}
                                                dataColors={"[]"}
                                              />
                                            </div>
                                          </div>

                                          <Row className="justify-content-center mt-3">
                                            <Col md={12}>
                                              <div className="mb-1">
                                                <i className="bx bxs-calendar text-info"></i>
                                                <span className="text-info"> Creation Date: {moment(item.userSubscriptionDateCreated).format("YYYY-MM-DD HH:mm")}</span>
                                              </div>

                                              {item.userSubscriptionStatus === SubscriptionStatus.DRAFT ||
                                                item.userSubscriptionStatus === SubscriptionStatus.READY ||
                                                item.userSubscriptionStatus === SubscriptionStatus.PAID ? (
                                                <>
                                                  <p className="mb-1 visibility-hidden">Activate to show statistics</p>
                                                  <p className="mb-1">
                                                    <i className="bx bxs-info-circle text-primary"></i>
                                                    <span className="text-primary">Activate to show statistics</span>
                                                  </p>
                                                  <p className="mb-1 visibility-hidden">Activate to show statistics</p>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="mb-1">
                                                    <i className="bx bx-calendar-check text-primary"></i>
                                                    <span className="text-primary"> Start Date: {moment(item.currentStageIndexDateFrom).format("YYYY-MM-DD")}</span>
                                                  </div>
                                                  <p className="mb-1">
                                                    <i className="bx bx-loader-circle text-primary"></i>
                                                    <span className="text-primary">
                                                      {" "}
                                                      Stage: {item.fundTradingProgramStageType === "PHASE" ? item.fundTradingProgramStageSequence : "LIVE"}
                                                    </span>
                                                  </p>
                                                  <div className="mb-1">
                                                    <i className={item.currentStageIndexTotalRealizedPnl >= 0 ? "text-success bx bx-money" : "text-danger bx bx-money"}></i>
                                                    <span className={item.currentStageIndexTotalRealizedPnl >= 0 ? "text-success" : "text-danger"}>
                                                      {" "}
                                                      PNL: ${Number(item.currentStageIndexTotalRealizedPnl).toFixed(2)} USDT
                                                    </span>
                                                  </div>
                                                </>
                                              )}

                                              {item.currentStageIndexFlags && item.currentStageIndexFlags.includes(SubscriptionIndexFlagType.LARGE_PROFIT_IN_ONE_ORDER) && (
                                                <div className="mb-1">
                                                  <i className="text-danger bx bxs-info-circle"></i>
                                                  <span className="text-danger"> Single trade profit limit exceeded.</span>
                                                </div>
                                              )}
                                              {item.currentStageIndexFlags && item.currentStageIndexFlags.includes(SubscriptionIndexFlagType.ORDER_IN_WRONG_CONTRACT) && (
                                                <div className="mb-1">
                                                  <i className="text-danger bx bxs-info-circle"></i>
                                                  <span className="text-danger"> Unsupported trade protocol.</span>
                                                </div>
                                              )}
                                            </Col>
                                          </Row>
                                        </div>
                                        <div className="px-4 py-3 border-top text-center">{this.displayActionButtons(item)}</div>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>

                                <PagerComponent currentPage={this.state.filter.page} pageSize={this.state.filter.pageSize} totalItems={this.state.filter.total} />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(AccountsPage);
