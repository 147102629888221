import { MoonPayProvider } from "@moonpay/moonpay-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ProtectedRoute } from "./components/ProtectedRoute";

// Import scss
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/theme.scss";

// Pages
import NotificationsPage from "./pages/NotificationsPage";
import AccountProgressPage from "./pages/account/AccountProgressPage";
import AccountsPage from "./pages/account/AccountsPage";
import AffiliateApplyPage from "./pages/affiliate/AffiliateApplyPage";
import AffiliatePage from "./pages/affiliate/AffiliatePage";
import AffiliatePayoutsPage from "./pages/affiliate/AffiliatePayoutsPage";
import AcceptInvitationPage from "./pages/auth/AcceptInvitationPage";
import PasswordResetPage from "./pages/auth/PasswordResetPage";
import RequestPasswordResetPage from "./pages/auth/RequestPasswordResetPage";
import SignInPage from "./pages/auth/SignInPage";
import SignUpPage from "./pages/auth/SignUpPage";
import BillingPage from "./pages/purchases/PurchasesPage";
import ContactPage from "./pages/contact/ContactPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import EarnsPage from "./pages/payouts/PayoutsPage";
import PricingDetailsPage from "./pages/pricing/PricingDetailsPage";
import PricingPage from "./pages/pricing/PricingPage";
import ProfilePage from "./pages/profile/ProfilePage";
import ProfileSettingsPage from "./pages/profile/ProfileSettingsPage";
import RankPage from "./pages/rank/RankPage";
import Terms from "./pages/terms/TermsOfConditionsPage";
import AmbassadorsPage from "./pages/ambassadors/AmbassadorsPage";

export class App extends React.Component {
  render() {
    return (
      <MoonPayProvider apiKey={process.env.REACT_APP_MOONPAY_API_KEY} debug>
        <React.Fragment>
          <Routes>
            {/* public pages */}
            <Route path="terms" element={<Terms />} />
            <Route path="sign-in" element={<SignInPage />} />
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="forgot-password" element={<RequestPasswordResetPage />} />
            <Route path="reset-password" element={<PasswordResetPage />} />
            <Route path="invitation" element={<AcceptInvitationPage />} />
            {/* public pages */}

            {/* secured pages */}
            <Route path="/" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
            <Route path="dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
            <Route path="profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
            <Route path="profile/settings" element={<ProtectedRoute><ProfileSettingsPage /></ProtectedRoute>} />
            <Route path="accounts" element={<ProtectedRoute><AccountsPage /></ProtectedRoute>} />
            <Route path="account-progress/:userSubscriptionId/:userSubscriptionStageIndexId" element={<ProtectedRoute><AccountProgressPage /></ProtectedRoute>} />
            <Route path="ambassador" element={<ProtectedRoute><AmbassadorsPage /></ProtectedRoute>} />
            <Route path="affiliate" element={<ProtectedRoute><AffiliatePage /></ProtectedRoute>} />
            <Route path="affiliate-payouts" element={<ProtectedRoute><AffiliatePayoutsPage /></ProtectedRoute>} />
            <Route path="affiliate-apply" element={<ProtectedRoute><AffiliateApplyPage /></ProtectedRoute>} />
            <Route path="billing" element={<ProtectedRoute><BillingPage /></ProtectedRoute>} />
            <Route path="earns" element={<ProtectedRoute><EarnsPage /></ProtectedRoute>} />
            <Route path="rank" element={<ProtectedRoute><RankPage /></ProtectedRoute>} />
            <Route path="contact" element={<ProtectedRoute><ContactPage /></ProtectedRoute>} />
            <Route path="notifications" element={<ProtectedRoute><NotificationsPage /></ProtectedRoute>} />
            <Route path="billing" element={<ProtectedRoute><BillingPage /></ProtectedRoute>} />
            <Route path="pricing" element={<ProtectedRoute><PricingPage /></ProtectedRoute>} />
            <Route path="pricing-details/:fundTradingProgramId" element={<ProtectedRoute><PricingDetailsPage /></ProtectedRoute>} />
            {/* secured pages */}
          </Routes>
          <ToastContainer />
        </React.Fragment>
      </MoonPayProvider>
    );
  }
}
