import axios, { AxiosRequestConfig } from 'axios';
import { AuthCookieManager } from '../AuthCookieManager';
import { ServerEndpointBuilder } from "../core/ServerDriver/ServerEndpointBuilder";
import { UrlParamPair } from '../core/ServerDriver/UrlParamPair';
import { CentralServerEndpointKind, CentralServerPathParamKind } from "./CentralServerClientEnums";
import { FilterUserAffiliatePayoutRequestDto } from './dto/affiliate/FilterUserAffiliatePayoutRequestDto';
import { FilterUserAffiliatePayoutResponseDto } from './dto/affiliate/FilterUserAffiliatePayoutResponseDto';
import { FilterViewUserAffiliatePurchaseShareResponseDto } from './dto/affiliate/FilterViewUserAffiliatePurchaseShareResponseDto';
import { UserAffiliateApplyRequestDto } from './dto/affiliate/UserAffiliateApplyRequestDto';
import { UserAffiliateApplyResponseDto } from './dto/affiliate/UserAffiliateApplyResponseDto';
import { UserAffiliateDto } from './dto/affiliate/UserAffiliateDto';
import { AcceptInvitationRequestDto } from './dto/auth/AcceptInvitationRequestDto';
import { AcceptInvitationResponseDto } from './dto/auth/AcceptInvitationResponseDto';
import { ChangePasswordRequestDto } from './dto/auth/ChangePasswordRequestDto';
import { ChangePasswordResponseDto } from './dto/auth/ChangePasswordResponseDto';
import { PasswordResetRequestDto } from "./dto/auth/PasswordResetRequestDto";
import { PasswordResetResponseDto } from './dto/auth/PasswordResetResponseDto';
import { SignInRequestDto } from './dto/auth/SignInRequestDto';
import { SignInResponseDto } from './dto/auth/SignInResponseDto';
import { SignUpRequestDto } from './dto/auth/SignUpRequestDto';
import { SignUpResponseDto } from './dto/auth/SignUpResponseDto';
import { IProgramAddonItem } from './dto/config/IProgramAddonItem';
import { ContactFormDto } from './dto/profile/ContactFormDto';
import { CreatePasswordResetDto } from "./dto/profile/CreatePasswordResetDto";
import { CreatePasswordResetResponseDto } from './dto/profile/CreatePasswordResetResponseDto';
import { FundTradingProgramDto } from './dto/programs/FundTradingProgramDto';
import { ActivateUserSubscriptionDto } from './dto/subscriptions/ActivateUserSubscriptionDto';
import { CredentialsDto } from './dto/subscriptions/CredentialsDto';
import { FilterUserSubscriptionPayoutRequestDto } from './dto/subscriptions/FilterUserSubscriptionPayoutRequestDto';
import { FilterUserSubscriptionPayoutResponseDto } from './dto/subscriptions/FilterUserSubscriptionPayoutResponseDto';
import { FilterUserSubscriptionPurchaseRequestDto } from './dto/subscriptions/FilterUserSubscriptionPurchaseRequestDto';
import { FilterUserSubscriptionPurchaseResponseDto } from './dto/subscriptions/FilterUserSubscriptionPurchaseResponseDto';
import { FilterUserSubscriptionRequestDto } from './dto/subscriptions/FilterUserSubscriptionRequestDto';
import { FilterUserSubscriptionStageIndexDayOrderDto } from "./dto/subscriptions/FilterUserSubscriptionStageIndexDayOrderDto";
import { FilterViewUserSubscriptionProgressInfoRequestDto } from './dto/subscriptions/FilterViewUserSubscriptionProgressInfoRequestDto';
import { FilterViewUserSubscriptionProgressInfoResponseDto } from './dto/subscriptions/FilterViewUserSubscriptionProgressInfoResponseDto';
import { PayoutSubscriptionDto } from './dto/subscriptions/PayoutSubscriptionDto';
import { PurchaseUserSubscriptionRequestDto } from './dto/subscriptions/PurchaseUserSubscriptionRequestDto';
import { UserAffiliatePayoutDataDto } from './dto/subscriptions/UserAffiliatePayoutDataDto';
import { UserSubscriptionDto } from './dto/subscriptions/UserSubscriptionDto';
import { UserSubscriptionPurchaseDto } from './dto/subscriptions/UserSubscriptionPurchaseDto';
import { UserSubscriptionStageIndexDayDto } from './dto/subscriptions/UserSubscriptionStageIndexDayDto';
import { UserSubscriptionStageIndexDto } from './dto/subscriptions/UserSubscriptionStageIndexDto';
import { UserBadgeDto } from './dto/user/UserBadgeDto';
import { UserAmbassadorDto } from './dto/ambassador/UserAmbassadorDto';
import { AmbassadorDto } from './dto/ambassador/AmbassadorDto';
import { AmbassadorPayoutRequestDto } from './dto/ambassador/AmbassadorPayoutRequestDto';
import { UserAffiliateGetInfoDto } from './dto/affiliate/UserAffiliateGetInfoDto';

type CentralErrorHandler = (error: unknown) => void;

export class CentralServerClient {
    // #region Private fields
    private readonly _endpointBuilder: ServerEndpointBuilder;
    // private _accessToken: string;
    // #endregion

    // #region Properties
    public get endpointBuilder(): ServerEndpointBuilder {
        return this._endpointBuilder;
    }

    // public get accessToken(): string {
    //     return this._accessToken;
    // }

    // public set accessToken(value: string) {
    //     this._accessToken = value;
    // }

    errorHandler?: CentralErrorHandler;
    // #endregion

    // #region Constructor
    constructor() {
        this._endpointBuilder = new ServerEndpointBuilder(process.env.REACT_APP_BASE_URL_API);
    }
    // #endregion

    // #region Fund Trading Program
    async getFundTradingProgramAddonList(): Promise<IProgramAddonItem[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.fund_trading_programs_addons.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: IProgramAddonItem[] = data as IProgramAddonItem[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    // #endregion

    // #region Auth endpoints
    async authSignIn(model: SignInRequestDto): Promise<SignInResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_sign_in.toString(), null, null);

            const { data } = await axios.post(url, model);
            const result: SignInResponseDto = data as SignInResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authSignUp(model: SignUpRequestDto): Promise<SignUpResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_sign_up.toString(), null, null);

            const { data } = await axios.post(url, model);
            const result: SignInResponseDto = data as SignInResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authRequestPasswordReset(model: CreatePasswordResetDto): Promise<CreatePasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_request_password_reset.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: CreatePasswordResetResponseDto = data as CreatePasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authResetPassword(model: PasswordResetRequestDto): Promise<PasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_reset_password.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: PasswordResetResponseDto = data as PasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authAcceptInvitation(model: AcceptInvitationRequestDto): Promise<AcceptInvitationResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_accept_invitation.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: AcceptInvitationResponseDto = data as AcceptInvitationResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Profile endpoints
    async profileChangePassword(model: ChangePasswordRequestDto): Promise<ChangePasswordResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.profile_change_password.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.patch(url, model, headers);
            const result: ChangePasswordResponseDto = data as ChangePasswordResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Communication
    async sendContactForm(model: ContactFormDto): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.send_contact_form.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: boolean = data as boolean;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Fund Trading Program
    async getSignature(moonpayUrl: string): Promise<string> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_get_signature.toString(), null, null);
            const headers = {};

            const { data } = await axios.post(url, { url: moonpayUrl }, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Users
    async getInvitedUserBadges(): Promise<UserBadgeDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.users_invited_badges.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Subscriptions endpoints
    async getFundTradingProgramList(): Promise<FundTradingProgramDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_programs_list.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: FundTradingProgramDto[] = data as FundTradingProgramDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getFundTradingProgram(fundTradingProgramId: string): Promise<FundTradingProgramDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_programs_id.toString(), [
                new UrlParamPair(CentralServerPathParamKind.fundTradingProgramId, fundTradingProgramId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: FundTradingProgramDto = data as FundTradingProgramDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterSubscriptions(model: FilterViewUserSubscriptionProgressInfoRequestDto): Promise<FilterViewUserSubscriptionProgressInfoResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_infos_filter_all.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterViewUserSubscriptionProgressInfoResponseDto = data as FilterViewUserSubscriptionProgressInfoResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterUserSubscriptions(model: FilterUserSubscriptionRequestDto): Promise<FilterViewUserSubscriptionProgressInfoResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_infos_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterViewUserSubscriptionProgressInfoResponseDto = data as FilterViewUserSubscriptionProgressInfoResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async buySubscription(model: PurchaseUserSubscriptionRequestDto): Promise<UserSubscriptionPurchaseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_buy.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: UserSubscriptionPurchaseDto = data as UserSubscriptionPurchaseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async activateSubscription(model: ActivateUserSubscriptionDto): Promise<UserSubscriptionDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_activate.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: UserSubscriptionDto = data as UserSubscriptionDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async payoutSubscription(model: PayoutSubscriptionDto): Promise<UserSubscriptionDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_payout_request.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: UserSubscriptionDto = data as UserSubscriptionDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async cancelUserSubscriptions(userSubscriptionId: string): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_cancel.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.patch(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterUserSubscriptionPayouts(model: FilterUserSubscriptionPayoutRequestDto): Promise<FilterUserSubscriptionPayoutResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_payouts_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserAffiliate(): Promise<UserAffiliateDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_get_data.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserAffiliateByUserId(model: UserAffiliateGetInfoDto): Promise<UserAffiliateDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_get_data_by_user_id.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getInvitedUsers(userAffiliateId?: string): Promise<FilterViewUserAffiliatePurchaseShareResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_invited.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, userAffiliateId, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getInvitationCode(): Promise<FilterViewUserAffiliatePurchaseShareResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.users_invitation_code.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getAmbassadorData(): Promise<AmbassadorDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_ambassadors_get_data.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getAmbassadorAffiliatesData(): Promise<UserAmbassadorDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_ambassadors_get_affiliates_data.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterUserAffiliatePayouts(model: FilterUserAffiliatePayoutRequestDto): Promise<FilterUserAffiliatePayoutResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_payouts_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async setUserAffiliatePayoutData(model: UserAffiliatePayoutDataDto): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_set_payout_data.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async setAmbassadorPayoutData(model: UserAffiliatePayoutDataDto): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_ambassadors_set_payout_data.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async ambassadorPayout(model: AmbassadorPayoutRequestDto): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_ambassadors_payout.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async userAffiliateApply(model: UserAffiliateApplyRequestDto): Promise<UserAffiliateApplyResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_apply.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterFilterUserSubscriptionPurchases(model: FilterUserSubscriptionPurchaseRequestDto): Promise<FilterUserSubscriptionPurchaseResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_purchases_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getCredentials(userSubscriptionId: string): Promise<CredentialsDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_credentials.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getProfileBadge(): Promise<UserBadgeDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.profile_badge.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStagesData(userSubscriptionId: string): Promise<UserSubscriptionStageIndexDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stages_indexes.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndex(userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<UserSubscriptionStageIndexDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndexDays(userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<UserSubscriptionStageIndexDayDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index_days.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndexDayOrders(userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<FilterUserSubscriptionStageIndexDayOrderDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index_day_orders_filter.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Private Functions
    private getHeaders(): AxiosRequestConfig {
        const token = AuthCookieManager.getToken();

        const headers: AxiosRequestConfig = {};

        if (token) {
            headers.headers = {
                Authorization: `Bearer ${token}`
            };
        }

        return headers;
    }
    // #endregion
}