import React from "react";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import withRouterHook from "../../withRouterHook";
import { Alert, Button, Card, CardBody, Form, Input, Label } from "reactstrap";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import UserBadge from "../../components/Common/UserBadge";
import { UserBadgeDto } from "../../lib/drivers/dto/user/UserBadgeDto";

export type ProfilePageProps = {};

type ProfilePageState = {
  userBadge: UserBadgeDto;
  errorMessage: string;
  successMessage: string;
};

class ProfilePage extends React.Component<ProfilePageProps, ProfilePageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: ProfilePageState = {
    userBadge: null,
    errorMessage: "",
    successMessage: ""
  };

  refCurrentPassword: React.RefObject<HTMLInputElement>;
  refNewPassword: React.RefObject<HTMLInputElement>;
  refRepeatNewPassword: React.RefObject<HTMLInputElement>;

  constructor(props: ProfilePageProps) {
    super(props);

    this.refCurrentPassword = React.createRef();
    this.refNewPassword = React.createRef();
    this.refRepeatNewPassword = React.createRef();
  }

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const userBadge = await PortCentralServer.Repo.client.getProfileBadge();

    await this.setState({
      userBadge
    });
  };

  componentDidUpdate = async (prevProps: ProfilePageProps, prevState: ProfilePageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  async handleSubmitSignIn(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const currentPassword = this.refCurrentPassword.current.value;
    const newPassword = this.refNewPassword.current.value;
    const repeatNewPassword = this.refRepeatNewPassword.current.value;

    let errorMessage: string = "";

    if (currentPassword.length == 0) {
      errorMessage = "Current password not provided";
    } else if (newPassword.length == 0) {
      errorMessage = "New password not provided";
    } else if (repeatNewPassword.length == 0) {
      errorMessage = "Repeat new password password not provided";
    } else if (newPassword != repeatNewPassword) {
      errorMessage = "Passwords do not match";
    }

    if (errorMessage) {
      await this.setState((prevState) => {
        return { errorMessage: errorMessage };
      });
    } else {
      try {
        // ...

        const result = await PortCentralServer.Repo.client.profileChangePassword({
          currentPassword: currentPassword,
          newPassword: newPassword
        });

        if (result) {
          await this.setState((prevState) => {
            return { successMessage: "Password changed" };
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              {this.state.userBadge ?
                <UserBadge userBadge={this.state.userBadge} /> : null}
              <h4 className="card-title mb-4">Change Password</h4>

              <Card>
                <CardBody>
                  {this.state.errorMessage ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {this.state.errorMessage}
                    </Alert>
                  ) : null}

                  {this.state.successMessage ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {this.state.successMessage}
                    </Alert>
                  ) : null}

                  <Form className="form-horizontal" onSubmit={(e) => this.handleSubmitSignIn(e)}>
                    <div className="form-group mb-3">
                      <Label className="form-label">Current Password</Label>
                      <Input className="form-control" type="password" innerRef={this.refCurrentPassword} />
                      {/* {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null} */}
                    </div>
                    <div className="form-group mb-3">
                      <Label className="form-label">New Password</Label>
                      <Input className="form-control" type="password" innerRef={this.refNewPassword} minLength={5} />
                      {/* {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null} */}
                    </div>
                    <div className="form-group mb-3">
                      <Label className="form-label">Repeat New Password</Label>
                      <Input className="form-control" type="password" innerRef={this.refRepeatNewPassword} minLength={5} />
                      {/* {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null} */}
                    </div>
                    <div className="text-center mt-4">
                      <Button type="submit" color="primary">
                        Change Password
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(ProfilePage);
