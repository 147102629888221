import React from "react";
import { Card, CardBody, Row, Col, Progress, Button } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { AppContext } from "../../context/AppProvider";
import withRouterHook from "../../withRouterHook";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { LoaderComponent } from "../../components/LoaderComponent";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { AmbassadorAffiliateReferencePayoutStatus } from "../../lib/drivers/dto/enums/AmbassadorPayoutStatus";
import { AmbassadorDto } from "../../lib/drivers/dto/ambassador/AmbassadorDto";
import { BaseModal } from "../../components/modals/BaseModal";

export type AmbassadorsPageProps = {};

type AmbassadorsPageState = {
    isLoading: boolean;
    ambassadorData: AmbassadorDto;
    ambassadorAffiliatesData: AffiliateData[];
    isPayoutDataModalOpen: boolean;
    network: string;
    address: string;
    userDetails: {
        title: string;
        description: string;
    }[];
};

type AffiliateData = {
    userAffiliateId: string;
    affiliateUsername: string;
    invitationCode: string;
    totalInvitedUsers: number;
    totalPurchasesAmount: number;
    progress: number;
    isPayoutAvailable: boolean;
};

class AmbassadorsPage extends React.Component<AmbassadorsPageProps, AmbassadorsPageState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: AmbassadorsPageState = {
        isLoading: true,
        ambassadorData: null,
        isPayoutDataModalOpen: false,
        network: "ERC20",
        address: "",
        ambassadorAffiliatesData: [],
        userDetails: []
    };

    async componentDidMount() {
        try {
            const ambassadorData = await PortCentralServer.Repo.client.getAmbassadorData();
            if (ambassadorData.activeYn) {
                const ambassadorAffiliatesReferenceData = await PortCentralServer.Repo.client.getAmbassadorAffiliatesData();

                let totalPurchasesAmount = 0;
                let totalInvitedUsers = 0;
                let affiliatesData: AffiliateData[] = [];

                for (const ambassadorAffiliateReference of ambassadorAffiliatesReferenceData) {
                    const invitedUsers = await PortCentralServer.Repo.client.getInvitedUsers(ambassadorAffiliateReference.userAffiliateId);
                    const affiliateInfo = await PortCentralServer.Repo.client.getUserAffiliateByUserId({ userAffiliateId: ambassadorAffiliateReference.userAffiliateId });

                    let totalAffiliatetotalPurchasesAmount = 0;
                    let totalAffiliateInvitedUsers = 0;

                    for (const user of invitedUsers.data) {
                        totalAffiliateInvitedUsers++;
                        totalAffiliatetotalPurchasesAmount += Number(user.totalPurchasePaymentAmount);
                    }

                    let progress = ((totalAffiliatetotalPurchasesAmount / ambassadorData.targetAmount[0]) * 100) || 0;

                    affiliatesData.push({
                        userAffiliateId: ambassadorAffiliateReference.userAffiliateId,
                        affiliateUsername: affiliateInfo.username,
                        invitationCode: affiliateInfo.invitationCode,
                        totalInvitedUsers: totalAffiliateInvitedUsers,
                        totalPurchasesAmount: totalAffiliatetotalPurchasesAmount,
                        progress: progress,
                        isPayoutAvailable:
                            ambassadorAffiliateReference.payoutStatus === AmbassadorAffiliateReferencePayoutStatus.NOT_SET &&
                            progress >= 100
                    });

                    totalPurchasesAmount += totalAffiliatetotalPurchasesAmount;
                    totalInvitedUsers += totalAffiliateInvitedUsers;
                }

                this.setState({
                    ambassadorData: ambassadorData,
                    ambassadorAffiliatesData: affiliatesData,
                    userDetails: [
                        {
                            title: "Total Invited Users By Affiliate",
                            description: totalPurchasesAmount.toLocaleString()
                        },
                        {
                            title: "Total Purchases Amount (USDT)",
                            description: `${totalInvitedUsers.toLocaleString()} USDT`
                        }
                    ]
                });
            }
        } catch (error) {
            console.error("Error fetching payout data:", error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    handlePayout = async (userAffiliateId: string) => {
        await PortCentralServer.Repo.client.ambassadorPayout({ userAffiliateId });
    }

    handlePayoutDataModalClose = () => {
        this.setState({ isPayoutDataModalOpen: false });
    };

    handlePayoutDataSubmit = async () => {
        const { network, address } = this.state;

        await PortCentralServer.Repo.client.setAmbassadorPayoutData({ payoutNetwork: network, payoutAddress: address });

        if (network && address) {
            this.setState({
                isPayoutDataModalOpen: false,
                network: "ERC20",
                address: null
            });
        }
    };

    PayoutDataModalContent = () => {
        return (
            <div className="align-items-center text-center">
                <h3>Payout Data</h3>

                <div className="form-group mt-3">
                    <label>Network</label>
                    <select className="form-control" value={this.state.network} onChange={(e) => this.setState({ network: e.target.value })}>
                        <option value="ERC20">ERC20</option>
                        <option value="TRC20">TRC20</option>
                    </select>
                </div>

                <div className="form-group mt-3">
                    <label>Address</label>
                    <input type="text" className="form-control" value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} placeholder="Enter wallet address" />
                </div>
            </div>
        );
    };

    render() {
        const columns = [
            { Header: "Affiliate Username", accessor: "affiliateUsername" },
            { Header: "Invitation Code", accessor: "invitationCode" },
            { Header: "Total Users Invited", accessor: "totalInvitedUsers" },
            { Header: "Total Purchases Amount (USDT)", accessor: "totalPurchasesAmount" },
            {
                Header: "Progress",
                accessor: "progress",
                Cell: ({ value }: { value: number }) => (
                    <div className="d-flex align-items-center">
                        <Progress value={value} className="me-2" style={{ width: "80%" }} />
                        <span>{Math.round(value)}%</span>
                    </div>
                ),
            },
            {
                Header: "Actions",
                accessor: "isPayoutAvailable",
                Cell: ({ row }: { row: any }) => {
                    const { isPayoutAvailable } = row.original;
                    const { payoutAddress, payoutNetwork } = this.state.ambassadorData || {};

                    return isPayoutAvailable && payoutAddress && payoutNetwork ? (
                        <Button color="primary" size="sm" onClick={() => this.handlePayout(row.original.userAffiliateId)}>
                            Claim Payout
                        </Button>
                    ) : (
                        <span>N/A</span>
                    );

                },
            },
        ];

        return (
            <Layout>
                <div className="page-content">
                    <div className="container-fluid">
                        <BaseModal
                            isOpen={this.state.isPayoutDataModalOpen}
                            content={<this.PayoutDataModalContent />}
                            title={"Payout"}
                            onSubmitClick={() => this.handlePayoutDataSubmit()}
                            onCloseClick={() => this.handlePayoutDataModalClose()}
                        />

                        <Breadcrumbs title="Affiliates" breadcrumbItem="Ambassador" />
                        <Row>
                            <Col xl="12">
                                {this.state.isLoading ? (
                                    <LoaderComponent />
                                ) : (
                                    <>
                                        <Row className="justify-content-center">
                                            <Col xl="6" className="text-center">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    type="button"
                                                    onClick={() => {
                                                        this.setState({ isPayoutDataModalOpen: true });
                                                    }}
                                                >
                                                    <i className="bi bi-clipboard">Edit Payout credentials</i>
                                                </button>
                                                <br />
                                                <br />
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-center">
                                            {this.state.userDetails.map((detail, key) => (
                                                <Col md="3" key={key}>
                                                    <Card className="mini-stats-wid">
                                                        <CardBody>
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <p className="fw-medium">{detail.title}</p>
                                                                    <h4 className="mb-0">{detail.description}</h4>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                        <Card>
                                            <CardBody>
                                                <div className="mb-4 h4 card-title">Affiliates</div>

                                                <Row>
                                                    <TableContainer
                                                        columns={columns}
                                                        data={this.state.ambassadorAffiliatesData}
                                                        isGlobalFilter={false}
                                                        isAddOptions={false}
                                                        customPageSize={10}
                                                    />
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouterHook(AmbassadorsPage);
