import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Form, Input, Label, Row, InputGroup, InputGroupText } from "reactstrap";
import { AppContext } from "../../context/AppProvider";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { SignUpResponseDto } from "../../lib/drivers/dto/auth/SignUpResponseDto";
import withRouterHook from "../../withRouterHook";
import { parseCookies, setCookie, destroyCookie } from "nookies"; // Import nookies

import logo from "../../assets/images/favicon.png";

export type SignUpPageProps = {
  params?: ReturnType<typeof useParams>;
  navigate?: ReturnType<typeof useNavigate>;
  location?: ReturnType<typeof useLocation>;
};

type SignUpPageState = {
  here: boolean;
  valid: boolean;
  successMessage: string;
  errorMessage: string;
  passwordMismatch: boolean;
  passwordLength: boolean;
  invitationCode: string;
  isInvitationCodeDisabled: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
};

class SignUpPage extends React.Component<SignUpPageProps, SignUpPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: SignUpPageState = {
    here: true,
    valid: false,
    successMessage: null,
    errorMessage: null,
    passwordMismatch: false,
    passwordLength: false,
    invitationCode: "",
    isInvitationCodeDisabled: false,
    showPassword: false,
    showConfirmPassword: false
  };

  refEmail: React.RefObject<HTMLInputElement>;
  refFirstName: React.RefObject<HTMLInputElement>;
  refInvitationCode: React.RefObject<HTMLInputElement>;
  refLastName: React.RefObject<HTMLInputElement>;
  refPassword: React.RefObject<HTMLInputElement>;
  refConfirmPassword: React.RefObject<HTMLInputElement>;
  refCaptcha: React.RefObject<ReCAPTCHA>;

  constructor(props: SignUpPageProps) {
    super(props);

    this.refEmail = React.createRef();
    this.refPassword = React.createRef();
    this.refConfirmPassword = React.createRef();
    this.refFirstName = React.createRef();
    this.refLastName = React.createRef();
    this.refInvitationCode = React.createRef();
    this.refCaptcha = React.createRef();
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const invitationCode = queryParams.get("invitation");

    if (invitationCode) {
      // Set the cookie for 1 hour using nookies
      setCookie(null, "invitationCode", invitationCode, { maxAge: 3600, path: "/" });
      this.setState({ invitationCode, isInvitationCodeDisabled: true });
    } else {
      // Retrieve the cookie using nookies
      const cookies = parseCookies();
      const cookieInvitationCode = cookies["invitationCode"];
      if (cookieInvitationCode) {
        this.setState({ invitationCode: cookieInvitationCode, isInvitationCodeDisabled: true });
      }
    }
  }

  handleTogglePassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleToggleConfirmPassword = () => {
    this.setState((prevState) => ({ showConfirmPassword: !prevState.showConfirmPassword }));
  };

  async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const recaptchaToken = this.refCaptcha.current.getValue();

    if (!recaptchaToken) {
      this.context.notifyError("CAPTCHA not verified");
      return;
    }

    const email = this.refEmail.current.value;
    const password = this.refPassword.current.value;
    const confirmPassword = this.refConfirmPassword.current.value;
    const firstName = this.refFirstName.current.value;
    const lastName = this.refLastName.current.value;
    const invitationCode = this.refInvitationCode.current.value;

    if (password.length < 6) {
      this.setState({ passwordLength: true });
      return;
    } else {
      this.setState({ passwordLength: false });
    }

    if (password !== confirmPassword) {
      this.setState({ passwordMismatch: true });
      return;
    } else {
      this.setState({ passwordMismatch: false });
    }

    const res: SignUpResponseDto = await PortCentralServer.Repo.client.authSignUp({
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      recaptchaToken: recaptchaToken,
      invitationCode: invitationCode
    });

    if (res && res.access_token && res.access_token.length > 0) {
      // Remove the cookie using nookies
      destroyCookie(null, "invitationCode", { path: "/" });

      await this.context.signInSoft(res.access_token, null);

      if (this.props.navigate) {
        this.props.navigate("/pricing");
      }
    } else {
      this.setState({ errorMessage: "Email is already in use or RECAPTCHA is invalid." });
    }
  }

  handleReCaptchaChange = async (token: string) => {
    this.setState({ valid: true });
  };

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <Row>
                      <Col className="col-12 text-center text-white">
                        <div className="p-4">
                          <h5>Registration</h5>
                          <p>Sign up to get your BoneX Fund Account.</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="logo-light-element">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-dark">
                            <img src={logo} alt="" height="34" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.state.errorMessage ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.state.errorMessage}
                        </Alert>
                      ) : null}

                      {this.state.successMessage ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.state.successMessage}
                        </Alert>
                      ) : null}

                      <Form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input name="email" className="form-control" type="email" innerRef={this.refEmail} />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <InputGroup>
                            <Input name="password" type={this.state.showPassword ? "text" : "password"} innerRef={this.refPassword} />
                            <InputGroupText onClick={this.handleTogglePassword} style={{ cursor: "pointer" }}>
                              <i className={`bx ${this.state.showPassword ? "bx-show" : "bx-hide"}`}></i>
                            </InputGroupText>
                          </InputGroup>
                        </div>

                        {this.state.passwordLength && (
                          <Alert color="danger" className="mt-2">
                            Password should be at least 6 symbols long.
                          </Alert>
                        )}

                        <div className="mb-3">
                          <Label className="form-label">Confirm Password</Label>
                          <InputGroup>
                            <Input name="confirmPassword" type={this.state.showConfirmPassword ? "text" : "password"} innerRef={this.refConfirmPassword} />
                            <InputGroupText onClick={this.handleToggleConfirmPassword} style={{ cursor: "pointer" }}>
                              <i className={`bx ${this.state.showConfirmPassword ? "bx-show" : "bx-hide"}`}></i>
                            </InputGroupText>
                          </InputGroup>

                          {this.state.passwordMismatch && (
                            <Alert color="danger" className="mt-2">
                              Passwords do not match.
                            </Alert>
                          )}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">First name</Label>
                          <Input name="firstName" className="form-control" type="text" innerRef={this.refFirstName} />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Last name</Label>
                          <Input name="lastName" className="form-control" type="text" innerRef={this.refLastName} />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Invitation code</Label>
                          <Input
                            name="refInvitationCode"
                            className="form-control"
                            type="text"
                            innerRef={this.refInvitationCode}
                            defaultValue={this.state.invitationCode}
                            disabled={this.state.isInvitationCodeDisabled}
                          />
                        </div>

                        <div className="mt-3 d-grid">
                          <ReCAPTCHA ref={this.refCaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={this.handleReCaptchaChange} />
                        </div>

                        <div className="mt-3 d-grid">
                          <button className="btn btn-primary btn-block" type="submit" disabled={!this.state.valid}>
                            Sign up
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            By registering you agree to the BoneX Fund{" "}
                            <a className="text-primary" href="/terms">
                              Terms
                            </a>
                          </p>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Already have an account ?{" "}
                    <Link to="/sign-in" className="fw-medium text-primary">
                      {" "}
                      Login now{" "}
                    </Link>{" "}
                  </p>
                </div>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} BoneX Fund</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouterHook(SignUpPage);
